import React, { useState, useEffect } from "react";
import "./UpdateView.css";
import "../../../GlobalCss/Module/UpdateView/UpdateView.css";
import { useLocation } from "react-router-dom";
import { sessionStorage } from "es-storage";
// notification
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// ant design
import { Checkbox } from "antd";
import { Tooltip } from "antd";
import { Spin } from "antd";
import { Input } from "antd";
//antd tabs
import { Tabs as AntTabs } from "antd";
//
// API Methods
import {
  updateSingleUser,
  UpdateUserRBAC,
  getSingleUser,
  AdminRestUserPassword,
  ReactivateUser,
} from "../../../API/userAPI";

const plainOptions = [
  { label: "Create", value: "create" },
  { label: "Read", value: "read" },
  { label: "Update", value: "update" },
  { label: "Delete", value: "delete" },
];

// Define interface for product object
// interface User {
//   id: number;
//   fullName: string;
//   status: string;
//   email: string;
//   phone: string;
//   createdBy: string;
//   createdAt: string;
//   updatedAt: string;
//   role: string;
// }

const UpdateView = () => {
  //spin state
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  //
  const location = useLocation();
  // const moduleId = location.state?.moduleId;
  // Extract module ID from state

  const [routeUserID, setRouteUserId] = useState(location.state?.routeUserId);

  useEffect(() => {
    if (!routeUserID && location.state?.routeUserId) {
      const newRouteUserID = location.state.routeUserId;
      setRouteUserId(newRouteUserID);
      setEditUserID(newRouteUserID);
    }
  }, [location.state, routeUserID]);

  useEffect(() => {
    const fetchData = async () => {
      if (routeUserID) {
        await handleFetchUserData(routeUserID);
        setDataLoading(false);
      }
    };
    fetchData();
  }, [routeUserID]);

  //Edit user
  const [EditRecordNote, setEditRecordNote] = useState("");
  const [EditUserID, setEditUserID] = useState("");
  const [EditUserName, setEditUserName] = useState("");
  const [EditUserStatus, setEditUserStatus] = useState("");
  const [EditUserEmail, setEditUserEmail] = useState("");
  const [EditUserPassword, setEditUserPassword] = useState("");
  const [editPermissions, setEditPermissions] = useState([]);
  //
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  //   let EditRBACUserData: any[] = [];
  const [EditRBACUserData, setEditRBACUserData] = useState([]);

  //### Methods

  const onRBACChange = (
    moduleId: number,
    checkedValues: any,
    permissionID: any
  ) => {
    setEditPermissions((prevData: any) => {
      const updatedData = prevData.map((item: any) => {
        if (item.moduleID === moduleId) {
          return {
            ...item,
            Create: checkedValues.includes("create"),
            Read: checkedValues.includes("read"),
            Update: checkedValues.includes("update"),
            Delete: checkedValues.includes("delete"),
            ID: permissionID,
          };
        }
        return item;
      });

      // If moduleId is not found in the existing data, add it
      if (!prevData.some((item: any) => item.moduleID === moduleId)) {
        updatedData.push({
          moduleID: moduleId,
          Create: checkedValues.includes("Create"),
          Read: checkedValues.includes("Read"),
          Update: checkedValues.includes("Update"),
          Delete: checkedValues.includes("Delete"),
          ID: permissionID,
        });
      }

      // console.log(
      //   "Updated permissions for module",
      //   moduleId,
      //   "=",
      //   checkedValues
      // );
      //console.log("RBACNewUserData", updatedData);

      return updatedData;
    });
    // console.log("Updated permissions for module", moduleId, "=", checkedValues);
    // console.log("RBACNewUserData", RBACNewUserData);
  };
  //

  const handleFetchUserData = async (id: Number) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    try {
      var req: any = await getSingleUser(id);
      setEditUserName(req.data.fullName);
      setEditUserStatus(req.data.status);
      setEditUserEmail(req.data.email);
      setEditRecordNote(req.data.recordNote);
      if (!req.data.UserModules || req.data.UserModules <= 0) {
        const error = new Error("Invalid RBAC Loaded");
        throw error;
      }
      setEditRBACUserData(req.data.UserModules);
      // console.log(req);
      //setDataLoading(false);
    } catch (error: any) {
      //console.log(error);
      setDataLoading(true);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    }
  };
  //

  //
  const HandleUpdateUser = async (event: any) => {
    event.preventDefault();
    //alert("debug1");
    setUpdateLoading(false);

    try {
      var req = await updateSingleUser({
        userID: routeUserID,
        userName: EditUserName,
        userEmail: EditUserEmail,
        recordNote: EditRecordNote,
      });
      //console.log(req);
      //GetUsers();
      notifySuccess(req.data.msg);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    } finally {
      setUpdateLoading(false); // Set loading to false after request completion or error
    }
  };
  //

  //
  const HandleUpdateUserRBAC = async (event: any) => {
    event.preventDefault();
    try {
      if (!routeUserID) {
        throw new Error("Invalid User Identifier");
      }
      var req = await UpdateUserRBAC({
        UserId: routeUserID,
        RBAC: editPermissions,
        UpdatedBy: sessionStorage.get("user_name"),
      });
      //console.log(req);
      //GetUsers();
      notifySuccess(req.data.msg);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    }
  };
  //
  const HandleUserPasswordReset = async (event: any) => {
    event.preventDefault();
    try {
      setUpdateLoading(true);
      await AdminRestUserPassword({
        New_Password: EditUserPassword,
        UserID: routeUserID,
      });

      notifySuccess("");
    } catch (error: any) {
      //console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const HandleReactivation = async () => {
    // event.preventDefault();
    try {
      setUpdateLoading(true);
      if (!routeUserID) {
        throw new Error("FE: Invalid User ID");
      }
      await ReactivateUser(routeUserID);
      notifySuccess("User Reactivated Successfuly");
      await handleFetchUserData(routeUserID);
    } catch (error: any) {
      //console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const [activePage, setActivePage] = useState("subpage1");

  return (
    <div className="ModuleUpdateView">
      <Spin spinning={dataLoading}>
        <Spin spinning={updateLoading}>
          <form className="ModuleUpdateViewForm" onSubmit={HandleUpdateUser}>
            <div className="ModuleUpdateViewForm_Header">
              <div className="FormTitle">
                <h4>Update User Form</h4>
              </div>
              <div className="RecordID">
                <h1>{EditUserID}</h1>
              </div>
              {EditUserStatus === "soft_deleted" ? (
                <button
                  type="button"
                  className="moduleSaveBtn"
                  onClick={() => HandleReactivation()}
                >
                  Reactivate
                </button>
              ) : null}
              <button className="moduleSaveBtn" type="submit">
                Update
              </button>
            </div>
            <div className="ModuleUpdateViewForm_Body">
              <div>
                <label className="userlabels">
                  <Tooltip
                    title="Use Full Name!"
                    color="#333333"
                    placement="bottomLeft"
                  >
                    Name:
                  </Tooltip>
                  <input
                    type="text"
                    placeholder="e.g  Alex Morgan"
                    value={EditUserName}
                    onChange={(event) => setEditUserName(event.target.value)}
                    minLength={2}
                    maxLength={50}
                    required
                  ></input>
                </label>
                <label className="userlabels">
                  <Tooltip
                    title="Used to login to the system. Using a proper email address allows users to receive
                  email notice!"
                    color="#333333"
                    placement="bottomLeft"
                  >
                    Email
                  </Tooltip>
                  <input
                    type="email"
                    placeholder="example@example.com"
                    value={EditUserEmail}
                    onChange={(event) => setEditUserEmail(event.target.value)}
                    minLength={2}
                    maxLength={125}
                    required
                  ></input>
                </label>
                <label className="userlabels">
                  <Tooltip
                    title="The Current user status within the system"
                    color="#333333"
                    placement="bottomLeft"
                  >
                    Status
                  </Tooltip>
                  <input
                    type="text"
                    value={EditUserStatus}
                    onChange={(event) => setEditUserEmail(event.target.value)}
                    readOnly
                  ></input>
                </label>
              </div>
            </div>
          </form>
          <div className="ModuleCreateViewForm_Bottom">
            <div className="FormViewPage">
              <h3>Sections</h3>
              <>
                <AntTabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: "1",
                      label: "Access Rights",
                      children: (
                        <>
                          <form
                            className="FormPage"
                            onSubmit={HandleUpdateUserRBAC}
                          >
                            <h3>User Role Based Access Control</h3>
                            <div className="EditUserModuleRBA">
                              {EditRBACUserData.map((value: any) => (
                                <div key={value.id}>
                                  <h5>{value.Module.name}</h5>
                                  <>
                                    <Checkbox.Group
                                      options={plainOptions}
                                      defaultValue={
                                        value.Role.Permissions[0]
                                          ? Object.keys(
                                              value.Role.Permissions[0]
                                            ).filter(
                                              (key) =>
                                                value.Role.Permissions[0][key]
                                            )
                                          : []
                                      }
                                      onChange={(checkedValues) =>
                                        onRBACChange(
                                          value.Module.id,
                                          checkedValues,
                                          value.Role.Permissions[0].id
                                        )
                                      }
                                    />
                                  </>
                                </div>
                              ))}
                              <button className="AddBtn" type="submit">
                                Update RBAC
                              </button>
                            </div>
                          </form>
                        </>
                      ),
                    },
                    {
                      key: "2",
                      label: "Password",
                      children: (
                        <>
                          <form
                            className="FormPage"
                            onSubmit={HandleUserPasswordReset}
                          >
                            <h3>Change Password</h3>
                            <div className="EditUserModuleRBA">
                              <Input.Password
                                placeholder="input password"
                                visibilityToggle={{
                                  visible: passwordVisible,
                                  onVisibleChange: setPasswordVisible,
                                }}
                                onChange={(e) =>
                                  setEditUserPassword(e.target.value)
                                }
                                required
                              />
                              <button className="AddBtn" type="submit">
                                Change
                              </button>
                            </div>
                          </form>
                        </>
                      ),
                    },
                  ]}
                ></AntTabs>
              </>
            </div>

            <ReactQuill
              theme="snow"
              value={EditRecordNote}
              onChange={setEditRecordNote}
            />
          </div>
        </Spin>
      </Spin>
    </div>
  );
};

export default UpdateView;
