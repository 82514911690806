import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
////Access Controle Middle Wares
import {
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//
// api methods
import {
  getAllMailSync,
  deleteSingeleMailSync,
} from "../../../API/mailSyncAPI";
//notification
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//
//ant design
import { Empty, Spin } from "antd";
import { Modal as AntdModel } from "antd";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";
//mui icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useModuleContext } from "../../../Contexts/ModuleContext";
// Define interface for product object
interface Product {
  id: number;
  internalref: string; // If your data includes an internal reference
  name: string;
  model: string;
  brand_name: string;
  document: string;
  description: string;
  image: string;
  quantity: number;
  cost_price: number;
  sales_price: number;
  purchase_method: string;
  ProductCategorieId?: number;
  ProductCategorieUUID?: string;
  ProductSubCategorieId?: number;
  ProductSubCategorieUUID?: string;
  SupplierId?: number;
  WarehouseId?: number;
  ProductCategorie?: {
    uuid: string;
    name: string;
  };
  ProductSubCategorie?: {
    uuid: string;
    name: string;
  };
  Supplier?: {
    name: string;
  };
  Warehouse?: {
    name: string;
  };
  Product_Warehouses?: Array<{
    quantity: number;
  }>;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

const ListView = () => {
  //
  const { contextModuleId, moduleLink } = useModuleContext();
  // const moduleid = location.state?.moduleId;
  // console.log(moduleid);
  const navigate = useNavigate();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //mui table state
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //product states
  const [productData, setproductData] = useState<Product[]>([]);

  //   Model methods
  const GetProducts = async () => {
    try {
      var req: any = await getAllMailSync();
      setproductData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetProducts();
  }, []);

  //   mui data grid methods
  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      const call = await deleteSingeleMailSync({ id: id });
      // console.log(call);
      GetProducts();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //
  //mui data Columns
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "subject", headerName: "Subject", width: 100 },

    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Last Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() =>
                    navigate(`update`, { state: { routeRecordId: id } })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  //onClick={handleDeleteClick(id)}
                  onClick={() => {
                    AntdModel.confirm({
                      title: "Confirm",
                      content: "Are you sure you want to delete this record?",
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          {/* <Button>Custom Button</Button> */}
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: () => {
                        async function asyncDeleteCall() {
                          await handleDeleteClick(id);
                        }
                        asyncDeleteCall();
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];
  //

  return (
    <div>
      <Spin spinning={loading}>
        {/* Add Export Button */}
        {productData && productData.length > 0 ? (
          <DataGrid
            rows={productData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  // createdAt: false,
                  // createdBy: false,
                  // updatedAt: false,
                  // updatedBy: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default ListView;
