import axios from 'axios';

interface CreateNotificationParams {
  user_id: number;
  message: string;
  type?: 'info' | 'success' | 'warning' | 'error';
  title?: string;
  link?: string;
  tenant_id: number;
}

class NotificationService {
  static async createNotification(params: CreateNotificationParams) {
    try {
      const response = await axios.post('/api/notifications/create', params);
      return response.data;
    } catch (error) {
      console.error('Error creating notification:', error);
      throw error;
    }
  }

  static async getNotifications(userId: number, page = 1, limit = 10, unreadOnly = false) {
    try {
      const response = await axios.get(`/api/notifications/user/${userId}`, {
        params: { page, limit, unreadOnly }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }
  }

  static async markAsRead(notificationId: number) {
    try {
      const response = await axios.put(`/api/notifications/${notificationId}/read`);
      return response.data;
    } catch (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  }

  static async markAllAsRead(userId: number) {
    try {
      const response = await axios.put(`/api/notifications/user/${userId}/mark-all-read`);
      return response.data;
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      throw error;
    }
  }

  static async getUnreadCount(userId: number) {
    try {
      const response = await axios.get(`/api/notifications/user/${userId}/unread-count`);
      return response.data.count;
    } catch (error) {
      console.error('Error fetching unread count:', error);
      throw error;
    }
  }

  static async deleteNotification(notificationId: number) {
    try {
      const response = await axios.delete(`/api/notifications/${notificationId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting notification:', error);
      throw error;
    }
  }
}

export default NotificationService;
