import React, { useState, useEffect } from "react";
import "./Inventory.css";
import "../../GlobalCss/Module/ModuleView.css";
////Access Controle Middle Wares
import {
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../middleware/authorizationMiddleware";
//
//ant design
import {
  DashboardOutlined,
  DollarOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
//
import { sessionStorage } from "es-storage";
//
import { Routes, Route, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import { Outlet, Link } from "react-router-dom";
//pages
import DashboardView from "./DashboardView/DashboardView";
import WarehouseMng from "./WarehouseMng/WarehouseMng";
import ProductCategorie from "./ProductCategory/ProductCategorie";
import ProductSubCategorie from "./ProductSubCategory/ProductSubCategorie";
//
import ProductSale from "./ProductSale/ProductSale";
//api controllers
// import { addProduct } from "../../API/productAPI";
// import { getAllSupplier } from "../../API/supplierAPI";
//mui
// import Button from "@mui/material/Button";
//mui for menu
//import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Transfer from "./Transfer/Transfer";
import { useModuleContext } from "../../Contexts/ModuleContext";
//

const Inventory = () => {
  //
  // const location = useLocation();
  // const moduleid = location.state?.moduleId;
  // console.log(moduleid);
  const { contextModuleId, moduleLink } = useModuleContext();
  // const [moduleid, setModuleId] = useState(location.state?.moduleId);

  // useEffect(() => {
  //   if (!moduleid && location.state?.moduleId) {
  //     setModuleId(location.state.moduleId);
  //   }
  // }, [location.state, moduleid]);
  //
  const navigate = useNavigate();
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  //

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  //module notifications
  // const notifyError = (message: any) =>
  //   toast.error(`error! \n ${message}`, {
  //     position: "top-center",
  //     theme: "dark",
  //   });
  // const notify_success = (message: any) =>
  //   toast.success(`Message: \n ${message}`, {
  //     position: "top-center",
  //     theme: "colored",
  //   });
  // //
  //states
  // const [ProductName, setProductName] = useState("");
  // const [ProductImage, setProductImage] = useState("");
  // const [ProductQuantity, setProductQuantity] = useState("");
  // const [ProductPrice, setProductPrice] = useState("");
  // const [ProductSupplierID, setProductSupplierID] = useState("");
  // const [ProductSupplierA, setProductSupplierA] = useState([]);
  // const ProductSupplier: any = [];
  //Methods

  // const handleAddProduct = async (event: any) => {
  //   event.preventDefault();

  //   try {
  //     //set suppliers
  //     //
  //     var req = await addProduct({
  //       Name: ProductName,
  //       Image: ProductImage,
  //       Quantity: ProductQuantity,
  //       Sales_Price: ProductPrice,
  //       Supplier_ID: ProductSupplierID,
  //     });
  //     console.log(req);
  //     notify_success(req.data);
  //   } catch (err: any) {
  //     console.log(err);
  //     notifyError(err.response.data.message);
  //   }
  // };

  // const handleImageChange = (e: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // Set the base64 string as the source of the image
  //       setProductImage(reader.result as string);
  //     };
  //     // Read the file as a data URL
  //     reader.readAsDataURL(file);
  //   }
  // };
  //

  //mui  menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //navigate("configuration");
    setAnchorEl(event.currentTarget);
    //navigate("configuration");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={() => {
              navigate("./");
            }}
          >
            Inventory
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          <div className="moduleMngActionBtns">
            <nav>
              <NavLink to="." end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <DashboardOutlined />
                    Dashboard
                  </Button>
                )}
              </NavLink>

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <NavLink to="product">
                  {({ isActive, isPending, isTransitioning }) => (
                    <Button className={isActive ? "active_module_nav" : ""}>
                      <UnorderedListOutlined />
                      Products
                    </Button>
                  )}
                </NavLink>
              )}

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <>
                  {CheckAdminAccess() ||
                  (CheckModuleCreateAccess(contextModuleId) &&
                    CheckModuleDeleteAccess(contextModuleId) &&
                    CheckModuleEditAccess(contextModuleId)) ? (
                    <Button onClick={handleClick}>
                      <ControlOutlined /> Configurations
                    </Button>
                  ) : null}

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    style={{
                      minWidth: "200px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("warehousemng");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <HomeOutlined
                          style={{ marginRight: "8px", fontSize: "18px" }}
                        />
                        Warehouse Management
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("productcategory");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Product Categories
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("productsubcategory");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Sub Categories
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("producttransfer");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Transfer
                      </label>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </nav>
          </div>
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<DashboardView />} />
          <Route path="/product/*" element={<ProductSale />} />
          <Route path="/warehousemng" element={<WarehouseMng />} />
          <Route path="/productcategory" element={<ProductCategorie />} />
          <Route path="/productsubcategory" element={<ProductSubCategorie />} />
          <Route path="/producttransfer" element={<Transfer />} />
        </Routes>
      </div>
    </div>
  );
};

export default Inventory;

// style={{
//   backgroundColor: "#4CAF50",
//   color: "white",
//   padding: "10px 20px",
//   fontSize: "16px",
//   fontWeight: "bold",
//   display: "flex",
//   alignItems: "center",
//   borderRadius: "8px",
//   border: "none",
//   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//   cursor: "pointer",
//   transition: "all 0.3s ease",
// }}
// onMouseEnter={(e) => {
//   const target = e.target as HTMLButtonElement;
//   target.style.backgroundColor = "#45a049";
//   target.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.2)";
// }}
// onMouseLeave={(e) => {
//   const target = e.target as HTMLButtonElement;
//   target.style.backgroundColor = "#4CAF50";
//   target.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
// }}
// onMouseDown={(e) => {
//   const target = e.target as HTMLButtonElement;
//   target.style.transform = "scale(0.95)";
// }}
// onMouseUp={(e) => {
//   const target = e.target as HTMLButtonElement;
//   target.style.transform = "scale(1)";
// }}

{
  /* <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        padding: "10px 20px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        border: "none",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                      }}
                      onMouseEnter={(e) => {
                        const target = e.target as HTMLButtonElement;
                        target.style.backgroundColor = "#45a049";
                        target.style.boxShadow =
                          "0px 6px 10px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        const target = e.target as HTMLButtonElement;
                        target.style.backgroundColor = "#4CAF50";
                        target.style.boxShadow =
                          "0px 4px 6px rgba(0, 0, 0, 0.1)";
                      }}
                      onMouseDown={(e) => {
                        const target = e.target as HTMLButtonElement;
                        target.style.transform = "scale(0.95)";
                      }}
                      onMouseUp={(e) => {
                        const target = e.target as HTMLButtonElement;
                        target.style.transform = "scale(1)";
                      }}
                    >
                      <ControlOutlined /> Configurations
                    </Button> */
}
