import React from "react";
import { useDraggable } from "@dnd-kit/core";

type DraggableProps = {
  id: string;
  title: string;
  description: string;
  onClick: () => void;
  onDelete: () => void; // New delete handler prop
};

export const Draggable = ({
  id,
  title,
  description,
  onClick,
  onDelete,
}: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });

  const style = transform
    ? {
        padding: "8px",
        border: "1px solid black",
        margin: "4px",
        cursor: "move",
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the drag event
    onDelete(); // Call the delete handler
  };
  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={style}
      onClick={onClick}
    >
      <div className="task-top">{title}</div>
      <div className="task-body">{description}</div>
      <div className="task-bottom">
        {/* <Button onClick={handleDeleteClick} type="primary" danger>
          Delete
        </Button> */}
      </div>
    </div>
  );
};
