import React, { useEffect, useState } from "react";
import "./CreateView.css";
//Api
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
} from "../../../../API/productAPI";
import { getAllProductCategories } from "../../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../../API/supplierAPI";
import { getAllWarehouse } from "../../../../API/warehouseAPI";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//notification
import {
  notifyError,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//
//antdesign
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Button, Form, Input, Space } from "antd";
// ant design icons
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
//antd tabs
import { Tabs as AntTabs } from "antd";
import type { TabsProps as AntTabsProps } from "antd";
//

////material Ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
//

// Define interface for product object
interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

const CreateView = () => {
  const [form] = Form.useForm();

  const [attributes, setAttributes] = useState<
    Array<{ name: string; value: string }>
  >([]);

  const onFinish = (values: any) => {
    // const event = new Event("form");
    // event.preventDefault();
    // alert(values);
    console.log("Received values of form:", values);
    setAttributes(values.attributes || []);
  };
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //New Product Variables
  const [NewProductName, setNewProductName] = useState("");
  const [NewProductImage, setNewProductImage] = useState("");
  const [NewProductDoc, setNewProductDoc] = useState("");
  const [NewProductDescription, setNewProductDescription] = useState("");
  const [NewProductQuantity, setNewProductQuantity] = useState("");
  const [NewProductMinStockQuantity, setNewProductMinStockQuantity] =
    useState("");
  const [NewProductMaxStockQuantity, setNewProductMaxStockQuantity] =
    useState<Number>(0);
  const [NewProductPrice, setNewProductPrice] = useState("");
  const [NewProductCostPrice, setNewProductCostPrice] = useState("");
  const [NewProductPurchasedMethod, setNewProductPurchasedMethod] =
    useState("");
  const [NewProductModel, setNewProductModel] = useState("");
  const [NewProductBrand, setNewProductBrand] = useState("");
  const [NewProductCategoryUUID, setNewProductCategoryUUID] = useState("");
  const [NewProductSubCategoryUUID, setNewProductSubCategoryUUID] =
    useState("");
  const [NewProductInternalRef, setNewProductInternalRef] = useState("");
  const [NewProductDocument, setNewProductDocument] = useState("");

  //
  const [NewProductWarehouseID, setNewProductWarehouseID] = useState("");
  const [ProductWarehouseA, setProductWarehouseA] = useState([]);
  const ProductWarehouse: any = [];
  //

  //
  const [NewProductCategorieID, setNewProductCategorieID] = useState("");
  const [NewProductSubCategorieID, setNewProductSubCategorieID] = useState("");
  const [ProductCategorieA, setProductCategorieA] = useState([]);
  const ProductCategorie: any = [];
  //
  const [NewProductCategory, setNewProductCategory] = useState("");
  const [NewProductSupplierID, setNewProductSupplierID] = useState("");
  const [ProductSupplierA, setProductSupplierA] = useState([]);
  const ProductSupplier: any = [];
  //
  const EditSubCategoryArray: any = [];
  //
  const [productWarehouseData, setproductWarehouseData] = useState<any[]>([]);
  const [productSubCategorieData, setProductSubCategorieData] = useState([]);
  const [filterdSubCategorieData, setfilterdSubCategorieData] = useState<any[]>(
    []
  );
  const [productData, setproductData] = useState<Product[]>([]);
  //note for the current recors
  const [RecordNote, setRecordNote] = useState("");

  //Model Methods
  const handleAddProduct = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addProduct({
        Name: NewProductName,
        InternalRef: NewProductInternalRef,
        Model: NewProductModel,
        Brand_Name: NewProductBrand,
        Purchase_Method: NewProductPurchasedMethod,
        Document: NewProductDocument,
        Description: NewProductDescription,
        Image: NewProductImage,
        Quantity: NewProductQuantity,
        MinStockQuantity: NewProductMinStockQuantity,
        MaxStockQuantity: NewProductMaxStockQuantity,
        Cost_Price: NewProductCostPrice,
        Sales_Price: NewProductPrice,
        Supplier_ID: NewProductSupplierID,
        Warehouse_ID: NewProductWarehouseID,
        ProductCategorie_ID: NewProductCategorieID,
        ProductSubCategorie_ID: NewProductSubCategorieID,
        ProductAttributes: attributes,
        Record_Note: RecordNote,
      });
      console.log(req);
      //GetProducts();
      notifySuccess(req.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const HandleGetProductCategories = async () => {
    //e.preventDefault();
    try {
      const call = await getAllProductCategories();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductCategorie.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      console.log(ProductCategorie);
      setProductCategorieA(ProductCategorie);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductSubCategories = async () => {
    //e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      for (let i = 0; i < data.length; i++) {
        EditSubCategoryArray.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      //console.log(EditSubCategoryArray);
      setProductSubCategorieData(call.data);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetWarehouses = async () => {
    //e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductWarehouse.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductWarehouse);
      setProductWarehouseA(ProductWarehouse);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetSuppliers = async () => {
    // e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductSupplier.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductSupplier);
      setProductSupplierA(ProductSupplier);
    } catch (err) {
      console.log(err);
    }
  };

  // const GetProducts = async () => {
  //   try {
  //     var req: any = await getAllProduct();
  //     setproductData(req.data);
  //     console.log(req.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const featchPreData = async () => {
    await HandleGetSuppliers();
    await HandleGetWarehouses();
    await HandleGetProductCategories();
    await HandleGetProductSubCategories();
  };
  useEffect(() => {
    ///GetProducts();
    featchPreData();
  }, []);
  //
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setNewProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  //
  // Function to update internal reference based on Model and Brand Name
  const updateInternalReference = () => {
    const internalRef = `${NewProductCategoryUUID}_${NewProductSubCategoryUUID}_${NewProductBrand}_${NewProductModel}`;
    setNewProductInternalRef(internalRef);
  };
  // Update internal reference whenever Model or Brand Name changes
  useEffect(() => {
    updateInternalReference();
  }, [
    NewProductModel,
    NewProductBrand,
    NewProductCategorieID,
    NewProductCategoryUUID,
    NewProductSubCategoryUUID,
  ]);
  //
  const handleFilterdSubCategoryChange = async (
    parentCategoryID: number | String
  ) => {
    console.log(parentCategoryID);
    console.log(productSubCategorieData);
    //
    let fillterdSubCategory = productSubCategorieData.filter(
      (value: any) => value.ProductCategorieId == parentCategoryID
    );
    console.log(fillterdSubCategory);
    // let array = [];
    // array.push(fillterdSubCategory);
    setfilterdSubCategorieData(fillterdSubCategory);
  };
  //

  //mui tabs
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [tabValue, settabValue] = React.useState(0);
  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    settabValue(newValue);
  };
  //

  return (
    <div className="ModuleCreateView">
      <form className="ModuleCreateViewForm" onSubmit={handleAddProduct}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Product Form</h4>
              <button className="moduleSaveBtn" type="submit">
                Save
              </button>
            </div>

            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="Unique ID generated from category, brand & model. !"
                  color="#333333"
                  placement="bottomLeft"
                >
                  <span>Internal Reference:</span>
                </Tooltip>
                <input
                  className="InternalRefInput"
                  type="text"
                  placeholder="01_01_Ustunel_S4CR8D03/15"
                  value={NewProductInternalRef}
                  minLength={2}
                  maxLength={100}
                  required
                  readOnly
                />
              </label>
              <label>
                Product Name:
                <input
                  type="text"
                  placeholder="e.g Pump"
                  onChange={(event) => setNewProductName(event.target.value)}
                  required
                />
              </label>
              <label className="productlabels">
                Product Quantity:
                <input
                  type="number"
                  placeholder="Enter quantity"
                  onChange={(event) =>
                    setNewProductQuantity(event.target.value)
                  }
                  min={1}
                  required
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                Brand Name:
                <input
                  type="text"
                  placeholder="e.g Caterpillar, Ustunel"
                  onChange={(event) => setNewProductBrand(event.target.value)}
                  required
                />
              </label>
              <label className="productlabels">
                Model:
                <input
                  type="text"
                  placeholder="S4CR8D03/15"
                  onChange={(event) => setNewProductModel(event.target.value)}
                  minLength={2}
                  maxLength={100}
                  required
                />
              </label>
              <label className="productlabels">
                <Tooltip
                  title="Price at which the product is sold to customers!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Sales Price:
                </Tooltip>
                <input
                  type="number"
                  placeholder="100.00"
                  onChange={(event) => setNewProductPrice(event.target.value)}
                  min={1}
                  required
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                Cost Price:
                <input
                  type="number"
                  placeholder="100.00"
                  onChange={(event) =>
                    setNewProductCostPrice(event.target.value)
                  }
                  required
                />
              </label>
              <label>
                Product Category:
                <select
                  multiple={false}
                  onChange={async (event) => {
                    const selectedOption = event.target.value;
                    const selectedCategoryId = selectedOption.split(",")[0];
                    const selectedCategoryUuid = selectedOption.split(",")[1];

                    setNewProductCategorieID(selectedCategoryId);
                    setNewProductCategoryUUID(selectedCategoryUuid);
                    handleFilterdSubCategoryChange(selectedCategoryId);
                  }}
                  required
                >
                  <option value="">Select a Product Categorie</option>
                  {ProductCategorieA.map((categorie: any) => (
                    <option
                      key={categorie.id}
                      value={`${categorie.id},${categorie.uuid}`}
                    >
                      {categorie.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="productlabels">
                <Tooltip
                  title="Product sub-categories found. Filtred based on main Categories.Set up categories in configuration settings!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Sub Category:
                </Tooltip>
                <select
                  multiple={false}
                  onChange={(event) => {
                    const selectedOption = event.target.value;
                    const selectedCategoryId = selectedOption.split(",")[0];
                    const selectedCategoryUuid = selectedOption.split(",")[1];

                    setNewProductSubCategorieID(selectedCategoryId);
                    setNewProductSubCategoryUUID(selectedCategoryUuid);
                  }}
                  required
                >
                  <option value="">Select Sub Categorie</option>
                  {filterdSubCategorieData.map((categorie: any) => (
                    <option
                      key={categorie.id}
                      value={`${categorie.id},${categorie.uuid}`}
                    >
                      {categorie.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                Product Supplier:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductSupplierID(event.target.value)
                  }
                  required
                >
                  <option value="">Select a supplier</option>
                  {ProductSupplierA.map((supplier: any) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </label>
              <label className="productlabels">
                Warehouse:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductWarehouseID(event.target.value)
                  }
                  required
                >
                  <option value="">Select a Warehouse</option>
                  {ProductWarehouseA.map((Warehouse: any) => (
                    <option key={Warehouse.id} value={Warehouse.id}>
                      {Warehouse.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="productlabels">
                Method of Purchase:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductPurchasedMethod(event.target.value)
                  }
                  required
                >
                  <option value="">Select a Method of Purchase</option>
                  <option value="direct purchase">Direct Purchase</option>
                  <option value="import">Import</option>
                </select>
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                Product Image:
                <div className="image-upload-container">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    multiple={false}
                    name="Add Image"
                  />
                  {NewProductImage && (
                    <img
                      src={NewProductImage}
                      alt="Uploaded"
                      className="ProductPreviewImg"
                    />
                  )}
                </div>
              </label>
              <label className="productlabels">
                Product Description:
                <textarea
                  placeholder="Detailed Product Description e.g., Ustunel 4” Submersible Pump"
                  onChange={(event) =>
                    setNewProductDescription(event.target.value)
                  }
                ></textarea>
              </label>
            </div>
            <div className="form-row">
              <AntTabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: "Stock levels",
                    children: (
                      <>
                        <label className="productlabels">
                          Minimum Stock Quantity:
                          <input
                            type="number"
                            onChange={(event) =>
                              setNewProductMinStockQuantity(event.target.value)
                            }
                            min={1}
                          />
                        </label>
                        <label className="productlabels">
                          Maximum Stock Quantity:
                          <input
                            type="number"
                            // value={NewProductMaxStockQuantity}
                            onChange={(e) =>
                              setNewProductMaxStockQuantity(
                                Number(e.target.value)
                              )
                            }
                          />
                        </label>
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: "Attributes",
                    children: (
                      <>
                        <Form
                          name="dynamic_form_nest_item"
                          onFinish={onFinish}
                          // onValuesChange={onFinish}
                          style={{ maxWidth: 600 }}
                          autoComplete="off"
                          // onValuesChange={(changedValues, allValues) => {
                          //   console.log("Changed values:", allValues);

                          //   form.setFieldsValue({
                          //     attributes: allValues.attributes,
                          //   });
                          // }}
                          onValuesChange={(changedValues, allValues) => {
                            console.log("Changed values:", changedValues);
                            console.log("All form values:", allValues);
                            console.log(
                              "Current attributes state:",
                              attributes
                            );

                            if (allValues.attributes) {
                              setAttributes(allValues.attributes);
                              console.log(
                                "Updated attributes:",
                                allValues.attributes
                              );
                            }
                          }}
                          initialValues={{
                            attributes: attributes,
                          }}
                        >
                          <Form.List name="attributes">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space
                                    key={key}
                                    style={{ display: "flex", marginBottom: 8 }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "name"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute name",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Attribute Name" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "value"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Attribute Value" />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Attribute
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                          {/* <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={(e) => e.preventDefault()}
                      >
                        Save Attributes
                      </Button>
                    </Form.Item> */}
                        </Form>
                      </>
                    ),
                  },
                ]}
              ></AntTabs>
            </div>
          </div>
          <ReactQuill theme="snow" onChange={setRecordNote} />
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
