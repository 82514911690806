import { sessionStorage } from "es-storage";
const rbacArray: { UserModules: any } = sessionStorage.getObject("user_rbac");

interface Module {
    id: number;
    name: string;
    description: string;
    iconUrl: string;
    link: string;
  }

const CheckTenantTelegramModuleAccess = (modules: Module[]): boolean => {
    if (!Array.isArray(modules)) {
        console.warn('Invalid modules array provided');
        return false;
    }

    const TELEGRAM_MODULE_NAME = 'Telegram Integration';
    
    const moduleAccess = modules.find(
        (module: Module) => module.name === TELEGRAM_MODULE_NAME
    );

    // console.log("moduleAccess", moduleAccess);

    if (moduleAccess) {
        return true;
    }
    return false;
    
};

export {
    CheckTenantTelegramModuleAccess
};