import React, { useEffect, useState } from "react";
import "./ExchangeRates.css";
import { Carousel } from "antd";
// API
import { getExchangeRates } from "../../API/exchangeRateAPI";

const ExchangeRate = () => {
  const [rates, setRates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const data: any = await getExchangeRates();

        // Check if data.data exists and is an array, and if the array is not empty
        if (Array.isArray(data.data) && data.data.length > 0) {
          //   console.log(data.data[0]); // Log the first item
          //   setRates([data.data[0]]); // Set only the first item in the state
          setRates(data.data);
        } else {
          // If the data array is empty or undefined, fallback to an empty array
          setRates([]); // Ensure rates is set to an empty array if no valid data is found
        }
      } catch (err) {
        setError("Failed to fetch exchange rates.");
        console.error(err); // Log the error for debugging
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRates();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="exchange-rates-container">
      <Carousel autoplay dots={false}>
        {rates && rates.length > 0
          ? rates.map((rate: any, index) => (
              <div key={index} className="exchange-rates-board">
                <h4>{rate.bank.replace(/_/g, " ")}</h4>
                <div className="exchange-rates-list">
                  {Object.entries(rate.rates).map(([currency, rate]: any) => (
                    <div key={currency} className="exchange-rates-item">
                      <span className="exchange-rates-currency">
                        {currency}:
                      </span>
                      <span className="exchange-rates-value">
                        Buy: {rate.cash_buying.toFixed(2)}, Sell:{" "}
                        {rate.cash_selling.toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))
          : null}
      </Carousel>
    </div>
  );
};

export default ExchangeRate;

//
// <div key={index} className="exchange-rates-board">
//   <h4>{rate.bank.replace(/_/g, " ")}</h4>
//   <div className="exchange-rates-list">
//     {Object.entries(rate.rates).map(([currency, rate]: any) => (
//       <div key={currency} className="exchange-rates-item">
//         <span className="exchange-rates-currency">{currency}:</span>
//         <span className="exchange-rates-value">
//           Buy: {rate.cash_buying.toFixed(2)}, Sell:{" "}
//           {rate.cash_selling.toFixed(2)}
//         </span>
//       </div>
//     ))}
//   </div>
// </div>
//
