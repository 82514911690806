import React, { useEffect, useState } from "react";
import "./ListView.css"; //Local Css
//notifications
import {
  notifySuccess,
  notifyError,
  notifyInfo,
  notifyWarning,
} from "../../../Components/Notifications/Notifications";

///ui
//antdesign
import { Button, Empty } from "antd";
import { Spin } from "antd";
//material ui
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//

const ListView = () => {
  //   ###model fields
  const [ListViewDate, setListViewDate] = useState([
    { id: 1, lastName: "Snow", firstName: "Jon", age: 14 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 31 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 31 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 11 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ]);

  //   ###Other fields
  //   spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //mui/list view columns
  const columns: GridColDef<(typeof ListViewDate)[number]>[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (value, row) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
    },
  ];

  return (
    <div className="ListView">
      <Spin spinning={loading}>
        <div className="ListView_Header">
          <div className="ListView_Header_Actions">
            <Button>Add</Button>
          </div>
        </div>
        <div className="ListView_Table">
          {ListViewDate && ListViewDate.length > 0 ? (
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={ListViewDate}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      id: false,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            </Box>
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ListView;
