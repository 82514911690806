import React, { useState, useEffect } from "react";
import "./UserMng.css";
import "../../GlobalCss/Module/ModuleView.css";
import { useModuleContext } from "../../Contexts/ModuleContext";
//
import { Routes, Route, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import { Outlet, Link } from "react-router-dom";
//
//auth middleware
import { CheckModuleCreateAccess } from "../../middleware/authorizationMiddleware";
//pages
import Dashboard from "./DashboardView/DashboardView";
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";

//ui
//#antdesign
//icons
import {
  DashboardOutlined,
  DollarOutlined,
  ControlOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Popover } from "antd";

const UserMng = () => {
  //
  const navigate = useNavigate();
  //
  const { contextModuleId } = useModuleContext();
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={(event) => {
              navigate(".");
            }}
          >
            Users
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          <nav>
            <div className="moduleMngActionBtns">
              <NavLink to="." end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <DashboardOutlined />
                    Dashboard
                  </Button>
                )}
              </NavLink>

              <NavLink to="list">
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <UnorderedListOutlined />
                    Users
                  </Button>
                )}
              </NavLink>

              <Popover title="Comming Soon!" trigger="click">
                <Button>
                  <SettingOutlined />
                  Configurations(V1)
                </Button>
              </Popover>
            </div>
          </nav>
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/list" element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
        </Routes>
      </div>
    </div>
  );
};

export default UserMng;
