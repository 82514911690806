import React, { useEffect, useState } from "react";
import {
  notifyError,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";
// ant design icons
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
//api methods
import { addQuotation } from "../../../../API/Sales/QuotationAPI";
import { getAllCustomer } from "../../../../API/customerAPI";
import { getAllProduct } from "../../../../API/productAPI";
//pdf
import { PDFViewer } from "@react-pdf/renderer";
import MyQuotationDocument from "../PDFPreview/PDFPreview";
//interface
interface customer {
  id: Number;
  name: String;
}

interface default_type {
  id: Number;
  name: String;
}
//

const CreateView = () => {
  //fields
  const [Name, seName] = useState("New");
  const [CustomerID, setCustomerID] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [QuotationDate, setQuotationDate] = useState("");
  const [QuotationTemplateID, setQuotationTemplateID] = useState("");
  const [QuotationTemplateList, setQuotationTemplateList] = useState([]);
  const [CurrencyID, setCurrencyID] = useState("");
  const [CurrencyList, setCurrencyList] = useState([]);
  const [PaymentTermID, setPaymentTermID] = useState("");
  const [PaymentTermList, setPaymentTermList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [QuotationLines, setQuotationLines] = useState<
    Array<{
      Product: string;
      Quantity: Number;
      Description: String;
      UnitPrice: Number;
      LineTotal: Number;
    }>
  >([]);
  //other fields
  const [IsPreViewPDFDrawerOpen, setIssPreViewPDFDrawerOpen] = useState(false);
  // pdf preview
  const showPreViewPDFDrawer = () => {
    setIssPreViewPDFDrawerOpen(true);
  };

  const handleClosePreViewPDFDrawer = () => {
    setIssPreViewPDFDrawerOpen(false);
  };
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  // var currDate = new Date();
  // currDate.setDate(currDate.getDate());
  // var today = currDate.toISOString().substring(0, 10);
  const today = new Date().toISOString().slice(0, 16);
  //methods
  const getCustmersList = async () => {
    try {
      const res = await getAllCustomer();
      setCustomerList(res.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
      }
    }
  };

  const handleAddRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addQuotation({
        Name: Name,
        Quotation_Date: QuotationDate,
        Customer_ID: CustomerID,
        Quotation_Lines: QuotationLines,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const getProductList = async () => {
    try {
      const res = await getAllProduct();
      setProductList(res.data);
      console.log(res.data);
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
      }
    }
  };
  //
  useEffect(() => {
    getCustmersList();
    getProductList();
  }, []);
  return (
    <div>
      <button className="moduleSaveBtn" onClick={() => showPreViewPDFDrawer()}>
        Preview
      </button>

      <form className="ModuleCreateViewForm" onSubmit={handleAddRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Quotation Form</h4>

              <button className="moduleSaveBtn" type="submit">
                Save
              </button>
            </div>
            <div className="form-row">
              <label className="productlabels">
                Customer:
                <Select
                  showSearch
                  placeholder="Select a Customer"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setCustomerID(value)}
                  options={CustomerList.map((customer: customer) => ({
                    value: customer.id,
                    label: customer.name,
                  }))}
                />
              </label>
              <label className="productlabels">
                <Tooltip
                  title="Unique ID generated from category, brand & model. !"
                  color="#333333"
                  placement="bottomLeft"
                >
                  <span>Quotation Date:</span>
                </Tooltip>
                <input
                  type="datetime-local"
                  // defaultValue={today}
                  onChange={(e) => setQuotationDate(e.target.value)}
                  required
                />
              </label>
              <label className="productlabels">
                <Tooltip
                  title="Unique ID generated from category, brand & model. !"
                  color="#333333"
                  placement="bottomLeft"
                >
                  <span>Currency:</span>
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select a Currency"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setCurrencyID(value)}
                  options={CurrencyList.map((currency: default_type) => ({
                    value: currency.id,
                    label: currency.name,
                  }))}
                />
              </label>
              <label className="productlabels">
                <Tooltip
                  title="Unique ID generated from category, brand & model. !"
                  color="#333333"
                  placement="bottomLeft"
                >
                  <span>Payment Terms:</span>
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select a Payment Term"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setPaymentTermID(value)}
                  options={PaymentTermList.map((term: default_type) => ({
                    value: term.id,
                    label: term.name,
                  }))}
                />
              </label>
              <label className="productlabels">
                <Tooltip
                  title="Unique ID generated from category, brand & model. !"
                  color="#333333"
                  placement="bottomLeft"
                >
                  <span>Quotation Template:</span>
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select a Quotation Template"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setQuotationTemplateID(value)}
                  options={QuotationTemplateList.map((customer: customer) => ({
                    value: customer.id,
                    label: customer.name,
                  }))}
                />
              </label>
            </div>
            <Divider />
            <div className="form-row">
              <AntTabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: "Quotation Lines",
                    children: (
                      <>
                        <Form
                          name="dynamic_form_nest_item"
                          // onFinish={onFinish}
                          style={{ maxWidth: 600 }}
                          autoComplete="off"
                          onValuesChange={(changedValues, allValues) => {
                            if (allValues.attributes) {
                              setQuotationLines(allValues.attributes);
                              console.log(
                                "Updated lines:",
                                allValues.attributes
                              );
                            }
                          }}
                          initialValues={{
                            attributes: QuotationLines,
                          }}
                        >
                          <Form.List name="attributes">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space
                                    key={key}
                                    style={{ display: "flex", marginBottom: 8 }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "Product"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute name",
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        placeholder="Select a Product"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        // onChange={(value) => setCustomerID(value)}
                                        options={ProductList.map(
                                          (product: default_type) => ({
                                            value: product.id,
                                            label: product.name,
                                          })
                                        )}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "Description"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Description"
                                        readOnly
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "Quantity"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Qty" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "UnitPrice"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Unit Price"
                                        readOnly
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "LineTotal"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Amount" readOnly />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Line
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </>
                    ),
                  },
                ]}
              ></AntTabs>
            </div>
            <Divider />
            <div className="form-row">
              <div className="footerPricing">
                <input placeholder="Untaxed Amount"></input>
                <input placeholder="Tax"></input>
                <input placeholder="Total"></input>
              </div>
            </div>
          </div>
        </Spin>
      </form>

      {/* modals */}
      {/* pdf preview modal */}

      <Drawer
        title="Quotation Preview"
        open={IsPreViewPDFDrawerOpen}
        onClose={handleClosePreViewPDFDrawer}
        width="100VW"
      >
        <PDFViewer style={{ width: "100%", height: "100%" }}>
          <MyQuotationDocument />
        </PDFViewer>
      </Drawer>
    </div>
  );
};

export default CreateView;
