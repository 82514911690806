import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

interface tenant {
  Name: string;
  Logo: string;
  TaxID: string;
  Phone: string;
  Mobile: string;
  Address: string;
  Email: string;
  Website: string;
  POBox: string;
}

// interface tenantPreferenceInterface {
//   allow_users_reset_password_at_login: boolean;
//   product_edit_approval_required: boolean;
// }

const getTenant = async (id: any) => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_TENANT_GET_ONE}/${id}`, {
      headers: { "Content-Type": "application/json" },
    });

    //sessionStorage.set("tenanTID", req.data.id);
    // console.log(req);
    return req;
  } catch (error: any) {
    throw error;
  }
};

const updateTenantInfo = async (props: tenant) => {
  try {
    const cid = sessionStorage.get("tenant_id");

    var req: any = await axios.put(
      `${process.env.REACT_APP_TENANT_Update_ONE}/${cid}`,
      {
        name: props.Name,
        logo: props.Logo,
        taxid: props.TaxID,
        phone: props.Phone,
        mobile: props.Mobile,
        address: props.Address,
        email: props.Email,
        website: props.Website,
        pobox: props.POBox,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateTenantPreference = async () => {
  try {
    const cid = sessionStorage.get("tenant_id");
    // check tenant id
    if (!cid) {
      const err = new Error("Tenant ID Could't be found in sesstion!");
      throw err;
    }
    //
    const allow_users_reset_password_at_login: boolean = sessionStorage.get(
      "allow_users_reset_password_at_login"
    ) as unknown as boolean;
    if (allow_users_reset_password_at_login === null) {
      throw new Error(
        "allow_users_reset_password_at_login preference missing from session"
      );
    }
    const product_edit_approval_required: boolean = sessionStorage.get(
      "product_edit_approval_required"
    ) as unknown as boolean;
    if (product_edit_approval_required === null) {
      throw new Error(
        "product_edit_approval_required preference missing from session"
      );
    }
    //
    // const token = sessionStorage.get("token");
    if (!token) {
      throw new Error("Authorization token is missing!");
    }
    //
    var req: any = await axios.put(
      `${process.env.REACT_APP_TENANT_Prefernce_Update_ONE}/${cid}`,
      {
        allow_users_reset_password_at_login:
          allow_users_reset_password_at_login,
        product_edit_approval_required: product_edit_approval_required,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    throw err;
  }
};

export { getTenant, updateTenantInfo, updateTenantPreference };
