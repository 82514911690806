import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user_id = sessionStorage.get("user_id");
const current_user = sessionStorage.get("user_name");
//

const addUser = async (props: any) => {
  try {
    const token = sessionStorage.get("HS_token");
    var req = await axios.post(
      `${process.env.REACT_APP_USER_ADD_ONE}`,
      {
        name: props.Name,
        email: props.Email,
        phone: props.Phone,
        password: props.Password,
        role: props.Role,
        RBAC: props.RBAC,
        recordNote: props.Record_Note,
        CreatedBy: props.CreatedBy,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllUser = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_USER_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return req;
  } catch (err: any) {
    throw err;
  }
};

const getSingleUser = async (userID: Number) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_USER_GET_SINGLE}/${userID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingleUser = async (props: {
  userID: Number;
  userName: String;
  userEmail: String;
  recordNote: String;
}) => {
  try {
    const req = await axios.put(
      `${process.env.REACT_APP_USER_UPDATE_SINGLE}/${props.userID}`,
      {
        name: props.userName,
        email: props.userEmail,
        recordNote: props.recordNote,
      }, // Empty object as the second parameter for the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const softDeleteSingeleUser = async (UserID: any) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_USER_SOFT_DELETE_ONE}/${UserID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (error: any) {
    throw error;
  }
};

const deleteSingeleUser = async (UserID: any) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_USER_DELETE_ONE}/${UserID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (error: any) {
    throw error;
  }
};

const AdminRestUserPassword = async (props: {
  New_Password: String;
  UserID: Number;
}) => {
  //
  try {
    if (!current_user_id) {
      throw new Error("FE: Current User ID");
    }
    if (!props.New_Password) {
      throw new Error("FE: Invalid password");
    }
    var req = await axios.put(
      `${process.env.REACT_APP_ADMIN_REST_USER_PASSWORD}/${props.UserID}`,
      {
        NewPassword: props.New_Password,
      }, // Empty object as the second parameter for the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const ReactivateUser = async (UserID: any) => {
  try {
    if (!UserID) {
      throw new Error("FE: Invalid User ID");
    }
    var req = await axios.put(
      `${process.env.REACT_APP_USER_Reactivate_ONE}/${UserID}`,
      { status: "active" },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (error: any) {
    throw error;
  }
};

const ChangePassword = async (props: any) => {
  //
  if (props.New_Password !== props.Confirm_Passord) {
    alert("Error While Confiremetion");
  } else {
    try {
      var req = await axios.put(
        `${process.env.REACT_APP_USER_CHANGE_PASSWORD}/${current_user_id}`,
        {
          OldPassword: props.Old_Password,
          NewPassword: props.New_Password,
          UpdatedBy: current_user,
        }, // Empty object as the second parameter for the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return req;
    } catch (err: any) {
      throw err;
    }
  }
};

const ChangeAvater = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_USER_CHANGE_AVATER}/${current_user_id}`,
      {
        image: props.Image,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const UpdateUserRBAC = async (props: any) => {
  try {
    if (!Array.isArray(props.RBAC) || !props.RBAC.length) {
      // array does not exist, is not an array, or is empty
      // ⇒ do not attempt to process array
      throw new Error("NO Row Affected");
    }

    var req = await axios.put(
      `${process.env.REACT_APP_USER_CHANGE_RBAC}/${props.UserId}`,
      {
        // Employee: props.Employee,
        // Dashboard: props.Dashboard,
        // Discuss: props.Discuss,
        // Inventory: props.Inventory,
        // Customer: props.Customer,
        // Supplier: props.Supplier,
        // Sales: props.Sales,
        RBAC: props.RBAC,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const ForgotUserPassword = async (
  propTenantUUID: String,
  propResetEmail: String
) => {
  try {
    //Check
    if (!propTenantUUID) {
      throw new Error("Tenant UUID Not Proided");
    }
    if (!propResetEmail) {
      throw new Error("User Reset Email Not Proided");
    }
    //
    var req = await axios.put(
      `${process.env.REACT_APP_USER_FORGOT_PASSWORD}/${propTenantUUID}`,
      {
        UserEmail: propResetEmail,
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const ResetUserPassword = async (
  propToken: String,
  propNewPassword: String
) => {
  try {
    //Check
    if (!propToken) {
      throw new Error("Invalid Session");
    }
    if (!propNewPassword) {
      throw new Error("User New password Not Proided");
    }
    //

    var req = await axios.put(
      `${process.env.REACT_APP_USER_RESET_PASSWORD}/${propToken}`,
      {
        UserNewPassword: propNewPassword,
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const UpdateTelegramBotChatID = async (telegram_id: String) => {
  try {
    //Check
    if (!telegram_id) {
      throw new Error("Invalid TelegramId");
    }
    if (!current_user_id) {
      throw new Error("Invalid User ID");
    }
    //
    var req = await axios.put(
      `${process.env.REACT_APP_UPDATE_USER_TELEGRAMBOT_ID}/${current_user_id}`,
      {
        telegram_chatbot_id: telegram_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  getAllUser,
  getSingleUser,
  addUser,
  softDeleteSingeleUser,
  deleteSingeleUser,
  AdminRestUserPassword,
  ChangePassword,
  ChangeAvater,
  UpdateUserRBAC,
  updateSingleUser,
  ForgotUserPassword,
  ResetUserPassword,
  ReactivateUser,
  UpdateTelegramBotChatID,
};
