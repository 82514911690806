import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

const getAllModule = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_MODULE_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllTenantModule = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_MODULE_Tenant_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export { getAllModule, getAllTenantModule };
