import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addWarehouse = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_WAREHOUSE_ADD}`,
      {
        name: props.Name,
        address: props.Address,
        CreatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllWarehouse = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_WAREHOUSE_GETALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleWarehouse = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_WAREHOUSE_DELETEONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingeleWarehouse = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_WAREHOUSE_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        address: props.Address,
        UpdatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addWarehouse,
  getAllWarehouse,
  deleteSingeleWarehouse,
  updateSingeleWarehouse,
  //   deleteSingeleSupplier,
};
