import React, { useState } from "react";
import "./Profile.css";
//
import { useNavigate } from "react-router-dom";
//
import { sessionStorage } from "es-storage";
//

const Profile = () => {
  const [UserImage, setUserImage] = useState(sessionStorage.get("user_image"));
  const navigate = useNavigate();

  const handleNavigation = (e: any) => {
    e.preventDefault();
    navigate("/landing/profile_edit", { replace: false });
  };

  return (
    <div className="profile-page">
      <div className="profile-container">
        <h1 className="profile-title">My Profile</h1>
        <div className="profile-content">
          <div className="profile-sidebar">
            <div className="profile-image-container">
              <img
                className="profile-avatar"
                src={UserImage || "/default-avatar.png"}
                alt="User Avatar"
              />
            </div>
          </div>
          <div className="profile-details">
            <div className="profile-field">
              <label>User ID</label>
              <input
                type="text"
                value={sessionStorage.get("user_id")}
                readOnly
              />
            </div>
            <div className="profile-field">
              <label>User Name</label>
              <input
                type="text"
                value={sessionStorage.get("user_name")}
                readOnly
              />
            </div>
            <div className="profile-field">
              <label>Work Email</label>
              <input
                type="text"
                value={sessionStorage.get("user_email")}
                readOnly
              />
            </div>
            <div className="profile-field">
              <label>Work Phone</label>
              <input type="text" placeholder="Not specified" readOnly />
            </div>
            <div className="profile-field">
              <label>Address</label>
              <input type="text" placeholder="Not specified" readOnly />
            </div>
            <div className="profile-field">
              <label>Role</label>
              <input
                type="text"
                value={sessionStorage.get("user_role")}
                readOnly
              />
            </div>
            <div className="profile-field">
              <label>Telegram ID</label>
              <input
                type="text"
                value={sessionStorage.get("user_telegramid")}
                readOnly
              />
            </div>
            <div className="profile-actions">
              <button className="edit-profile-btn" onClick={handleNavigation}>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
