import React from "react";
import "./Setting5.css";
//

const Setting5 = () => {
  //
  return <div>Coming Soon</div>;
};

export default Setting5;
