import React, { useEffect, useState } from "react";
import "./DashboardView.css";
//Notifications
import { notifyError } from "../../../Components/Notifications/Notifications";
//Api
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
} from "../../../API/productAPI";
import { getAllProductCategories } from "../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../API/supplierAPI";
import { getAllWarehouse } from "../../../API/warehouseAPI";

const InventoryDashboard = () => {
  //
  const [TotProduct, setTotProduct] = useState(0);
  const [TotProductCategorie, setTotProductCategorie] = useState(0);
  const [TotProductSubCategorie, setTotProductSubCategorie] = useState(0);
  const [TotWarehouse, setTotWarehouse] = useState(0);
  //
  const [LastProduct, setLastProduct] = useState<any>("");
  //
  // const [stats, setStats] = useState({
  //   totalProducts: 0,
  //   inStock: 0,
  //   outOfStock: 0,
  //   lowStock: 0,
  // });
  // const [recentActivities, setRecentActivities] = useState([]);

  const GetProducts = async () => {
    try {
      var req: any = await getAllProduct();
      const data: any[] = req.data;
      // setproductData(req.data);
      console.log(req.data);
      setTotProduct(data.length);
      //
      const lastNumber = data[data.length - 1];
      // Check if the data array has any products
      if (data.length > 0) {
        // Get the last product
        const lastProduct = data[data.length - 1];
        console.log("Last product:", lastProduct);

        // Set the last product
        setLastProduct(lastProduct);
      } else {
        console.log("No products found.");
        // setLastProduct([]); // Or handle as appropriate
      }
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };

  //HandleGetProductCategories
  const HandleGetProductCategories = async () => {
    try {
      const call = await getAllProductCategories();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      //console.log(data.length);
      // for (let i = 0; i < data.length; i++) {
      //   ProductCategorie.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //     uuid: data[i].uuid,
      //   });
      // }
      // console.log(call);
      // setProductCategorie(data);
      setTotProductCategorie(data.length);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };

  const HandleGetProductSubCategories = async () => {
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      // for (let i = 0; i < data.length; i++) {
      //   EditSubCategoryArray.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //     uuid: data[i].uuid,
      //   });
      // }
      //console.log(EditSubCategoryArray);
      // setProductSubCategorieData(call.data);
      setTotProductSubCategorie(data.length);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };

  const HandleGetWarehouses = async () => {
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      // console.log(data.length);
      // for (let i = 0; i < data.length; i++) {
      //   ProductWarehouse.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //   });
      // }
      // console.log(ProductWarehouse);
      // setProductWarehouseA(ProductWarehouse);
      setTotWarehouse(data.length);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };
  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      await GetProducts();
      await HandleGetProductCategories();
      await HandleGetProductSubCategories();
      await HandleGetWarehouses();
    };

    fetchCategories();
  }, []);

  return (
    <div className="inventoryDashboard">
      <h1>Inventory Dashboard</h1>

      <div className="dashboardStats">
        <div className="statCard">
          <h2>Total Products</h2>
          <p>{TotProduct}</p>
        </div>
        <div className="statCard">
          <h2>Total Categories</h2>
          <p>{TotProductCategorie}</p>
        </div>
        <div className="statCard">
          <h2>Total Sub-Categories</h2>
          <p>{TotProductSubCategorie}</p>
        </div>
        <div className="statCard">
          <h2>Total Warehouse</h2>
          <p>{TotWarehouse}</p>
        </div>
      </div>

      <h2>Recent Add Product</h2>
      <table className="activityTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {LastProduct ? (
            <tr>
              <td>{LastProduct.name}</td>
              <td>{LastProduct.createdAt}</td>
              <td>{LastProduct.updatedAt}</td>
            </tr>
          ) : (
            <tr>
              <td colSpan={3}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryDashboard;
