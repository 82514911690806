import React, { useState } from "react";
import "./SupplierMng.css";
import "../../GlobalCss/Module/ModuleView.css";
//api methods
import { addSupplier } from "../../API/supplierAPI";
//
import SupplierTable from "./Tables/SupplierTable";
//notification
import { notifySuccess,notifyError } from "../../Components/Notifications/Notifications";
//ant design
import { Spin } from "antd";
//mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//
import { CheckModuleCreateAccess } from "../../middleware/authorizationMiddleware";
import { useModuleContext } from "../../Contexts/ModuleContext";
//

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

//

const SupplierMng = () => {
  // const location = useLocation();
  // const moduleid = location.state?.moduleId;
  const { contextModuleId, moduleLink } = useModuleContext();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //mui modal state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //supplier form stats
  const [supplierName, setSupplierName] = useState("");
  const [supplierMobile, setSupplierMobile] = useState("");
  const [supplierEmail, setSupplierEmail] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  //
  //methods
  const HandleAddSupplier = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      var req = await addSupplier({
        Name: supplierName,
        Mobile: supplierMobile,
        Email: supplierEmail,
        Address: supplierAddress,
      });
      console.log(req);
      notifySuccess(req.data.msg);
    } catch (err: any) {
      notifyError(err.response.data);
      console.log(err);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2 className="moduleName">Supplier</h2>
        </div>
        {/* <div>
          <label>Dashboard</label>
          <label>Product</label>
          <label>Configeration</label>
        </div> */}
      </div>
      <div className="moduleMngActionDiv">
        {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
          <button className="moduleCreateBtn" onClick={handleOpen}>
            New
          </button> // <div className="AppCard">Request The Admin for Log Modul</div>
        ) : null}
        {/* <div>
          <label>Filter</label>
          <label>Group</label>
          <label>Favorite</label>
        </div> */}
      </div>
      <div className="moduleDisplay">
        <SupplierTable ModuleID={contextModuleId} />
      </div>
      {/* mui model */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="ProductEditModalHeaderDiv">New Supplier Form</div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Spin spinning={loading}>
                <form onSubmit={HandleAddSupplier} style={{ width: "100%" }}>
                  <div className="formContainer">
                    <div className="formFields">
                      <label className="productlabels">
                        Supplier Name
                        <input
                          type="text"
                          placeholder="Supplier Name"
                          onChange={(event) =>
                            setSupplierName(event.target.value)
                          }
                          required
                        />
                      </label>

                      <label className="productlabels">
                        Supplier Mobile
                        <input
                          type="tel"
                          placeholder="Supplier Mobile"
                          onChange={(event) =>
                            setSupplierMobile(event.target.value)
                          }
                        />
                      </label>

                      <label className="productlabels">
                        Supplier Email
                        <input
                          type="email"
                          placeholder="Supplier Email"
                          onChange={(event) =>
                            setSupplierEmail(event.target.value)
                          }
                        />
                      </label>

                      <label className="productlabels">
                        Supplier Address
                        <textarea
                          placeholder="Supplier Address"
                          onChange={(event) =>
                            setSupplierAddress(event.target.value)
                          }
                        />
                      </label>

                      <div className="formActions">
                        <button className="addbtn" type="submit">
                          Add Supplier
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Spin>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/*  */}
    </div>
  );
};

export default SupplierMng;
