import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useModuleContext } from "../../../Contexts/ModuleContext";
//
import { CheckModuleCreateAccess } from "../../../middleware/authorizationMiddleware";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
//

const PaymentTerm = () => {
  //
  const navigate = useNavigate();
  //context
  const { contextModuleId, moduleLink } = useModuleContext();
  //
  return (
    <div>
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h3 className="moduleName" onClick={() => {
                navigate(".");
              }}>Payment Terms</h3>
        </div>

        <div className="moduleMngActionDiv">
          {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <button
              className="moduleCreateBtn"
              onClick={() => {
                navigate("create");
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.backgroundColor = "#e0a800";
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.backgroundColor = "#FFC107";
              }}
              onMouseDown={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.transform = "scale(1)";
              }}
              style={{
                backgroundColor: "#FFC107",
                border: "none",
                padding: "8px 16px",
                cursor: "pointer",
                transition: "all 0.2s ease",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "4px",
              }}
            >
              New
            </button>
          ) : (
            "Access denied"
          )}
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="create" element={<CreateView />} />
        </Routes>
      </div>
    </div>
  );
};

export default PaymentTerm;
