import * as React from "react";

import "./ProductSale.css";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
import ProductEditRequests from "../ProductEditApproval/ProductEditApproval";
import ProductEditApproval from "../ProductEditApproval/UpdateView/UpdateView";
//
import { Routes, Route } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckAdminAccess,
} from "../../../middleware/authorizationMiddleware";
//ant design icons
//
import { useNavigate } from "react-router-dom";
//
import { sessionStorage } from "es-storage";
import "react-toastify/dist/ReactToastify.css";

//midewares

//material ui badge

import Button from "@mui/material/Button";
//
import { useModuleContext } from "../../../Contexts/ModuleContext";
//

export default function ProductSale() {
  //
  //
  // const location = useLocation();
  // const moduleid = location.state?.moduleId;
  const { contextModuleId, moduleLink } = useModuleContext();
  // const [moduleid, setModuleId] = useState(location.state?.moduleId);
  // console.log(moduleid);
  const navigate = useNavigate();
  //

  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h3
            className="moduleName"
            onClick={() => {
              navigate(".");
            }}
          >
            Product For Sale
          </h3>
        </div>
        <div className="moduleMngActionDiv">
          {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <button
              className="moduleCreateBtn"
              onClick={() => {
                navigate("create");
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.backgroundColor = "#e0a800";
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.backgroundColor = "#FFC107";
              }}
              onMouseDown={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.transform = "scale(1)";
              }}
              style={{
                backgroundColor: "#FFC107",
                border: "none",
                padding: "8px 16px",
                cursor: "pointer",
                transition: "all 0.2s ease",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "4px",
              }}
            >
              New Product
            </button>
          ) : (
            "Access denied"
          )}
          {CheckAdminAccess() ? (
            JSON.parse(
              sessionStorage.get("product_edit_approval_required") || "false"
            ) ? (
              <Button
                onClick={() => {
                  navigate("productEditREQUESTS");
                }}
                onMouseEnter={(e) => {
                  const target = e.target as HTMLButtonElement;
                  target.style.backgroundColor = "#e0a800";
                }}
                onMouseLeave={(e) => {
                  const target = e.target as HTMLButtonElement;
                  target.style.backgroundColor = "#FFC107";
                }}
                onMouseDown={(e) => {
                  const target = e.target as HTMLButtonElement;
                  target.style.transform = "scale(0.95)";
                }}
                onMouseUp={(e) => {
                  const target = e.target as HTMLButtonElement;
                  target.style.transform = "scale(1)";
                }}
                style={{
                  backgroundColor: "#FFC107",
                  transition: "all 0.2s ease",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "4px",
                  padding: "8px 16px",
                }}
              >
                Approve Edit
              </Button>
            ) : null
          ) : null}
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
          <Route path="productEditREQUESTS" element={<ProductEditRequests />} />
          <Route path="productEditApproval" element={<ProductEditApproval />} />
        </Routes>
      </div>
    </div>
  );
}
