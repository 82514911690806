import React, { createContext, useContext, useState, useEffect } from "react";
import { sessionStorage } from "es-storage"; // Assuming you're using this package

// Define the module data structure
interface Module {
  id: number;
  name: string;
  description: string;
  iconUrl: string;
  link: string;
}

// Define the context data structure
interface TenantSubscriptionContextType {
  modules: Module[];
  setModules: (modules: Module[]) => void;
  addModule: (modules: Module[]) => void;
  removeModule: (moduleId: number) => void;
  clearModules: () => void;
}

// Create the context
const TenantSubscriptionContext = createContext<
  TenantSubscriptionContextType | undefined
>(undefined);

// Custom hook to use the TenantSubscription context
export const useTenantSubscriptionContext = () => {
  const context = useContext(TenantSubscriptionContext);
  if (!context) {
    throw new Error(
      "useTenantSubscriptionContext must be used within a TenantSubscriptionProvider"
    );
  }
  return context;
};

// Provider component
export const TenantSubscriptionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Initialize modules state by checking sessionStorage first
  const [modules, setModules] = useState<Module[]>(() => {
    const savedModules = sessionStorage.get("tenantSubModules"); // Updated key for consistency
    return savedModules ? JSON.parse(savedModules) : [];
  });

  // Effect to update sessionStorage whenever modules change
  useEffect(() => {
    if (modules.length > 0) {
      // Remove duplicates before saving to sessionStorage
      const uniqueModules = Array.from(
        new Set(modules.map((mod) => mod.id))
      ).map((id) => {
        return modules.find((mod) => mod.id === id) as Module;
      });

      sessionStorage.set("tenantSubModules", JSON.stringify(uniqueModules)); // Updated key
    }
  }, [modules]);

  // Add a new module, flattening any nested arrays
  const addModule = (modulesData: Module[]) => {
    // Flatten the array if it's nested
    const flatModules = modulesData.flat();

    setModules((prevModules) => {
      // Combine the new modules with the existing ones
      const combinedModules = [...prevModules, ...flatModules];

      // Remove duplicates based on module ID
      const uniqueModules = Array.from(
        new Set(combinedModules.map((mod) => mod.id))
      ).map((id) => {
        return combinedModules.find((mod) => mod.id === id) as Module;
      });

      return uniqueModules;
    });
  };

  // Remove a module by its ID
  const removeModule = (moduleId: number) => {
    setModules((prevModules) =>
      prevModules.filter((module) => module.id !== moduleId)
    );
  };

  // Clear modules (typically when logging out)
  const clearModules = () => {
    setModules([]);
    sessionStorage.remove("tenantSubModules"); // Clear from sessionStorage
  };

  return (
    <TenantSubscriptionContext.Provider
      value={{ modules, setModules, addModule, removeModule, clearModules }}
    >
      {children}
    </TenantSubscriptionContext.Provider>
  );
};
