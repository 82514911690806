import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addQuotation = async (props: any) => {
  try {
    //check Validations
    if (!current_user_id) {
      throw Error("Current User ID is Not Available/Invalid");
    }

    if (!token) {
      throw Error("Invalid Token");
    }

    //
    var req = await axios.post(
      `${process.env.REACT_APP_QUOTATION_ADD_ONE}`,
      {
        name: props.Name,
        quotation_date: props.Quotation_Date,
        customer_id: props.Customer_ID,
        quotation_lines: props.Quotation_Lines,
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getOneQuotation = async (id: Number) => {
  try {
    if (!id) {
      throw new Error("Client-Side-Error : Invalid ID");
    }
    if (!token) {
      throw new Error("Client-Side-Error : Invalid token");
    }
    //
    var req = await axios.get(
      `${process.env.REACT_APP_QUOTATION_GET_ONE}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllQuotation = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_QUOTATION_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

// const getAllApprovalQuotation = async () => {
//   try {
//     var req = await axios.get(
//       `${process.env.REACT_APP_QUOTATION_GET_APPROVED}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     console.log(req);
//     return req;
//   } catch (err: any) {
//     //console.log(err);
//     if ((err.response.status = 404)) {
//       // const navigate = useNavigate();
//       alert(err);
//       // navigate("/", { replace: true });
//     }
//     throw err;
//   }
// };

// const approveQuotation = async (props: any) => {
//   // alert(props.id)
//   try {
//     // alert(
//     //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
//     // );
//     var req = await axios.put(
//       `${process.env.REACT_APP_QUOTATION_APPROVE}/${props.ID}`,
//       {
//         status: props.Status,
//         UpdatedBy: current_user,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return req;
//   } catch (err: any) {
//     console.log(err);
//     if ((err.response.status = 404)) {
//       // const navigate = useNavigate();
//       alert(err);
//       // navigate("/", { replace: true });
//     }
//     throw err;
//   }
// };

const deleteSingeleQuotation = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_QUOTATION_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

export {
  addQuotation,
  getAllQuotation,
  getOneQuotation,
  deleteSingeleQuotation,
};
