import React, { useEffect, useState } from "react";
import {
  notifyError,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//
import { Route, Routes, useNavigate } from "react-router-dom";
//api

////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckAdminAccess,
  CheckModuleDeleteAccess,
  CheckModuleEditAccess,
} from "../../../../middleware/authorizationMiddleware";
//
import { useModuleContext } from "../../../../Contexts/ModuleContext";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";
//mui icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//antd
import { Spin } from "antd";
import { Modal as AntdModel } from "antd";
import { Empty } from "antd";
//

const ListView = () => {
  //
  const navigate = useNavigate();
  //context
  const { contextModuleId, moduleLink } = useModuleContext();
  //fields
  const [ListViewData, setListViewData] = useState([]);
  //other
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //mui data Columns
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "QuotationDate", headerName: "Quotation Date", width: 100 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Last Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              // onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              // onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() =>
                    navigate(`update`, { state: { routeRecordId: id } })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  //onClick={handleDeleteClick(id)}
                  onClick={() => {
                    AntdModel.confirm({
                      title: "Confirm",
                      content: "Are you sure you want to delete this record?",
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          {/* <Button>Custom Button</Button> */}
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: () => {
                        async function asyncDeleteCall() {
                          await handleDeleteClick(id);
                        }
                        asyncDeleteCall();
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];
  //Model methods
  const GetListViewData = async () => {
    try {
      setLoading(true);
      // var req: any = await getAllQuotation();
      // setListViewData(req.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };

  useEffect(() => {
    GetListViewData();
  }, []);
  //

  const handleDeleteClick = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      // const call = await deleteSingeleQuotation({ id: id });

      GetListViewData();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //
  return (
    <div>
      {" "}
      <Spin spinning={loading}>
        {ListViewData && ListViewData.length > 0 ? (
          <DataGrid
            rows={ListViewData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  image: false,
                  createdAt: false,
                  createdBy: false,
                  updatedAt: false,
                  updatedBy: false,
                  Product_Warehouses: false,
                  SupplierId: false,
                  WarehouseId: false,
                  Warehouse: false,
                  description: false,
                  document: false,
                  purchase_method: false,
                  ProductCategorieId: false,
                  ProductCategorieUUID: false,
                  ProductSubCategorieId: false,
                  ProductSubCategorieUUID: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default ListView;
