import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

export const botDirectMessage = async (
  TelegramBotID: String,
  Message: String
) => {
  try {
    var req = await axios.post(`${process.env.REACT_APP_TELEGRAM_BOT_DM}`, {
      chatID: TelegramBotID,
      message: Message,
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};
