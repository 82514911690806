import axios from "axios";
import { sessionStorage } from "es-storage";


const authSignIn = async (props: any) => {
  
  //alert(props.Email);
  //console.log(props);
  try {
    const setSessionStorage = async (data: any) => {
      await sessionStorage.set("HS_token", data.token);
      await sessionStorage.set("tenant_id", data.tenantID);
      await sessionStorage.set("user_id", data.uID);
      await sessionStorage.set("user_name", data.uName);
      await sessionStorage.set("user_email", data.uEmail);
      await sessionStorage.set("user_role", data.uRole);
      await sessionStorage.set("user_image", data.uImage);
      await sessionStorage.set("user_type", data.uType);
      await sessionStorage.setObject("user_rbac", data.uRBAC);
      //user telegram
      await sessionStorage.setObject("user_telegramid", data.uTelegramID);
      //
      await sessionStorage.set("company_name", data.CompanyInfo.Name);
      await sessionStorage.set("company_logo", data.CompanyInfo.Logo);
      await sessionStorage.set("company_address", data.CompanyInfo.Address);
      await sessionStorage.set("company_email", data.CompanyInfo.Email);
      await sessionStorage.set("company_mobile", data.CompanyInfo.Mobile);
      await sessionStorage.set("company_pobox", data.CompanyInfo.POBox);
      await sessionStorage.set("company_phone", data.CompanyInfo.Phone);
      await sessionStorage.set("company_taxid", data.CompanyInfo.TaxID);
      await sessionStorage.set("company_website", data.CompanyInfo.Website);
      //
      await sessionStorage.set("rbac_Discuss", "AccessDenied");
      //
      if (data.RBAC) {
        await sessionStorage.set("rbac_Employee", data.RBAC.Employee);
        await sessionStorage.set("rbac_Dashboard", data.RBAC.Dashboard);
        await sessionStorage.set("rbac_Discuss", data.RBAC.Discuss);
        // await sessionStorage.set("rbac_Discuss", "AccessDenied");
        await sessionStorage.set("rbac_Inventory", data.RBAC.Inventory);
        await sessionStorage.set("rbac_Customer", data.RBAC.Customer);
        await sessionStorage.set("rbac_Supplier", data.RBAC.Supplier);
        await sessionStorage.set("rbac_Sales", data.RBAC.Sales);
        // console.log("RBAC" + sessionStorage.get("rbac_Discuss"));
      }
      //
      await sessionStorage.set("stat_countCustomers", data.STAT.countCustomers);
      await sessionStorage.set("stat_countEmployees", data.STAT.countEmployees);
      await sessionStorage.set("stat_Discuss", data.STAT.Discuss);
      // await sessionStorage.set("rbac_Discuss", "AccessDenied");
      await sessionStorage.set("stat_countOrders", data.STAT.countOrders);
      await sessionStorage.set("stat_countProducts", data.STAT.countProducts);
      await sessionStorage.set(
        "stat_countQuotations",
        data.STAT.countQuotations
      );
      await sessionStorage.set("stat_countSuppliers", data.STAT.countSuppliers);
      //save tenant preferences
      // {
      //   data.TenantPreferences.allow_users_reset_password_at_login
      //     ? sessionStorage.set("allow_users_reset_password_at_login", true)
      //     : sessionStorage.set("allow_users_reset_password_at_login", false);
      // }
      try {
        const allowResetPassword: string =
          data.TenantPreferences.allow_users_reset_password_at_login;
        await sessionStorage.set(
          "allow_users_reset_password_at_login",
          allowResetPassword.toString()
        );
      } catch (error) {
        console.error("Error setting session storage:", error);
        throw new Error("Error setting Tenant Preference session storage");
      }
      // {
      //   data.TenantPreferences.product_edit_approval_required
      //     ? sessionStorage.set("product_edit_approval_required", true)
      //     : sessionStorage.set("product_edit_approval_required", false);
      // }
      await sessionStorage.set(
        "product_edit_approval_required",
        data.TenantPreferences.product_edit_approval_required || "false"
      );

      //

      //local
      //await localStorage.set("Company_Info", data.CompanyInfo);
      // sessionStorage.set("companyinfo", data.CompanyInfo);
      //const companyInfo = JSON.parse(await sessionStorage.get("companyinfo"));
      //const companyName = companyInfo && companyInfo.name;
      //console.log(companyName);
      //let ci = localStorage.get("Company_Info");
      // Retrieve the data from local storage
      // Retrieve the data from local storage

      //console.log(ci.id);
      // await localStorage.set(
      //   "Company_Info",
      //   JSON.stringify(data.CompanyInfo)
      // );

      // // Retrieving the "Company_Info" object from localStorage
      // let companyInfo:any = JSON.parse(localStorage.get("Company_Info"));
      // console.log(companyInfo.name);
    };
    //

    //

    const request = await axios.post(`${process.env.REACT_APP_LOGIN_API}`, {
      // tenantID: props.tenantID,
      tenantUUID: props.tenantUUID,
      email: props.email,
      password: props.password,
    });
    // console.log(request);
    //console.log(request.data);
    await setSessionStorage(request.data);

    await sessionStorage.set("tenant_uuid", props.tenantUUID);
    return request;
  } catch (error) {
    //console.log(error);
    //use throw to return it as error
    throw error;
  }
};

export { authSignIn };
