import React, { useState } from "react";
import "./Dashboard.css";
import UsersIcon from "../../assest/Dashboard/team.png";
import ProductsIcon from "../../assest/Dashboard/cubes.png";
import CustomersIcon from "../../assest/Dashboard/service.png";
import SuppliersIcon from "../../assest/Dashboard/supplier.png";
import QuotationsIcon from "../../assest/Dashboard/payment.png";

const Dashboard = () => {
  const [CountEmployees, setCountEmployees] = useState(120); // Placeholder for API data
  const [CountCustomers, setCountCustomers] = useState(85);
  const [CountOrders, setCountOrders] = useState(50);
  const [CountProducts, setCountProducts] = useState(200);
  const [CountQuotations, setCountQuotations] = useState(35);
  const [CountSuppliers, setCountSuppliers] = useState(15);

  return (
    <div className="Dashboard">
  <div className="cardsContainer">
    <div className="Card">
      <img src={UsersIcon} alt="Users Icon" />
      <label className="title">Employees</label>
      <label className="count">{CountEmployees}</label>
    </div>

    <div className="Card">
      <img src={ProductsIcon} alt="Products Icon" />
      <label className="title">Products</label>
      <label className="count">{CountProducts}</label>
    </div>

    <div className="Card">
      <img src={CustomersIcon} alt="Customers Icon" />
      <label className="title">Customers</label>
      <label className="count">{CountCustomers}</label>
    </div>

    <div className="Card">
      <img src={SuppliersIcon} alt="Suppliers Icon" />
      <label className="title">Suppliers</label>
      <label className="count">{CountSuppliers}</label>
    </div>

    <div className="Card">
      <img src={QuotationsIcon} alt="Quotations Icon" />
      <label className="title">Quotations</label>
      <label className="count">{CountQuotations}</label>
    </div>

    <div className="Card">
      <img src={QuotationsIcon} alt="Orders Icon" />
      <label className="title">Orders</label>
      <label className="count">{CountOrders}</label>
    </div>
  </div>
</div>

  );
};

export default Dashboard;
