import { Email } from "@mui/icons-material";
import { sessionStorage } from "es-storage";

type rbacArrayType = {
  UserModules: [
    {
      id: Number;
      RoleId: Number;
      TenantId: Number;
      UserId: Number;
      createdAt: Date;
      updatedAt: Date;
      Role: {
        Permissions: any[];
      };
      ModuleId: Number;
      Module: Object;
    }
  ];
};

const rbacArray: rbacArrayType | null = sessionStorage.getObject("user_rbac");

const Authorization = (
  application: string,
  obtained: string,
  required: string[]
): boolean => {
  // if (application == "Discuss" && obtained == "AccessDenied") {
  //   return false;
  // } else

  if (application === "Discuss" && required.includes(obtained)) {
    return true;
  }

  if (application === "Dashboard" && required.includes(obtained)) {
    return true;
  }

  if (application === "Employee" && required.includes(obtained)) {
    return true;
  }

  if (application === "Inventory" && required.includes(obtained)) {
    return true;
  }

  if (application === "Customer" && required.includes(obtained)) {
    return true;
  }

  if (application === "Support" && required.includes(obtained)) {
    return true;
  }

  if (application === "Sales" && required.includes(obtained)) {
    return true;
  } else {
    return false;
  }
};

const CheckAdminAccess = () => {
  // console.log("UserID=" + sessionStorage.get("user_id"));
  // console.log(Array.isArray(UserRbac.UserModules)); // Should return true
  // console.log(typeof UserRbac.UserModules); // Should return 'object'

  // const permission = UserRbac.UserModules.find(
  //   (p: { ModuleId: number }) => p.ModuleId === ModuleID
  // );
  if (sessionStorage.get("user_type") === "SuperAdmin") {
    return true;
  } else {
    return false;
  }
};

// const CheckUserModuleAccess = (ModuleID: string | number) => {

//   const UserModules = rbacArray.UserModules;
//   console.log(UserModules);

//   const moduleAccess = rbacArray.UserModules.find(
//     (p: { ModuleId: string }) => p.ModuleId === ModuleID
//   );
//   console.log(moduleAccess);
//   if (moduleAccess) {
//     if (moduleAccess.Role.Permissions[0].read) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };
const CheckUserModuleAccess = (ModuleID: number) => {
  if (ModuleID && Array.isArray(rbacArray?.UserModules)) {
    
    const moduleAccess = rbacArray?.UserModules?.find(
      (p) => p.ModuleId === ModuleID // Ensure both sides are compared as strings
    );

    if (moduleAccess) {
      // Check if Role and Permissions are defined
      const hasPermissions =
        moduleAccess.Role &&
        moduleAccess.Role.Permissions &&
        moduleAccess.Role.Permissions.length > 0;

      if (hasPermissions && moduleAccess.Role.Permissions[0]?.read) {
        return true; // User has read access
      } else {
        // console.log("User does not have read access.");
        return false; // User does not have read access
      }
    } else {
      // console.log("No access found for this ModuleID.");
      return false; // No access for this ModuleID
    }
  }
};

// const CheckUserModuleAccess = (
//   UserRbac: any,
//   ModuleID: number,
//   userID: number
// ) => {
//   console.log("UserID=" + sessionStorage.get("user_id"));
//   console.log(Array.isArray(UserRbac.UserModules)); // Should return true
//   console.log(typeof UserRbac.UserModules); // Should return 'object'

//   const permission = UserRbac.UserModules.find(
//     (p: { ModuleId: number }) => p.ModuleId === ModuleID
//   );
//   if (permission) {
//     return true;
//   } else {
//     return false;
//   }
// };

// const CheckModuleCreateAccess = (ModuleID: string) => {
//   console.log(ModuleID);
//   console.log("UserID=" + sessionStorage.get("user_id"));
//   console.log(Array.isArray(rbacArray.UserModules)); // Should return true
//   console.log(typeof rbacArray.UserModules); // Should return 'object'

//   if (ModuleID && Array.isArray(rbacArray.UserModules)) {
//     const moduleAccess = rbacArray.UserModules.find(
//       (p: { ModuleId: string }) => p.ModuleId === ModuleID
//     );
//     if (moduleAccess) {
//       if (moduleAccess.Role.Permissions[0].create) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };

const CheckModuleCreateAccess = (ModuleID: number) => {
  // Convert ModuleID to a string to ensure consistent comparison
  const moduleIdString = String(ModuleID);
  //console.log("Checking module create access for ModuleID:", moduleIdString);
  //console.log("UserID:", sessionStorage.getItem("user_id"));
  //console.log("Is UserModules array:", Array.isArray(rbacArray.UserModules));

  if (moduleIdString && Array.isArray(rbacArray?.UserModules)) {
    const moduleAccess = rbacArray?.UserModules.find(
      (p) => String(p.ModuleId) === moduleIdString // Ensure both sides are strings
    );

    // console.log("Module Access Found:", moduleAccess); // Log found access

    if (moduleAccess) {
      const hasPermissions =
        moduleAccess.Role.Permissions &&
        moduleAccess.Role.Permissions.length > 0;
      if (hasPermissions && moduleAccess.Role.Permissions[0]?.create) {
        return true; // User has create access
      } else {
        console.log("User does not have create access.");
        return false; // User does not have create access
      }
    } else {
      // console.log("No access found for this ModuleID.");
      return false; // No access for this ModuleID
    }
  } else {
    // console.log("Invalid ModuleID or UserModules is not an array.");
    return false; // Invalid ModuleID or UserModules not an array
  }
};

// const CheckModuleEditAccess = (ModuleID: string | number) => {
//   // console.log(ModuleID);
//   // console.log("UserID=" + sessionStorage.get("user_id"));
//   // console.log(Array.isArray(rbacArray.UserModules)); // Should return true
//   // console.log(typeof rbacArray.UserModules); // Should return 'object'

//   if (ModuleID && Array.isArray(rbacArray.UserModules)) {
//     const moduleAccess = rbacArray.UserModules.find(
//       (p: { ModuleId: number }) => p.ModuleId === ModuleID
//     );
//     if (moduleAccess) {
//       if (moduleAccess.Role.Permissions[0].update) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };
const CheckModuleEditAccess = (ModuleID: number) => {
  // console.log("Checking edit access for ModuleID:", ModuleID);
  // console.log("Is UserModules an array?", Array.isArray(rbacArray.UserModules));

  if (ModuleID && Array.isArray(rbacArray?.UserModules)) {
    const moduleAccess = rbacArray?.UserModules.find(
      (p) => String(p.ModuleId) === String(ModuleID) // Ensure both sides are compared as strings
    );

    // console.log("Module Access Found:", moduleAccess); // Log found access

    if (moduleAccess) {
      // Check if Role and Permissions are defined
      const hasPermissions =
        moduleAccess.Role &&
        moduleAccess.Role.Permissions &&
        moduleAccess.Role.Permissions.length > 0;

      if (hasPermissions && moduleAccess.Role.Permissions[0]?.update) {
        return true; // User has edit access
      } else {
        // console.log("User does not have edit access.");
        return false; // User does not have edit access
      }
    } else {
      // console.log("No access found for this ModuleID.");
      return false; // No access for this ModuleID
    }
  } else {
    // console.log("Invalid ModuleID or UserModules is not an array.");
    return false; // Invalid parameters
  }
};

// const CheckModuleDeleteAccess = (ModuleID: string | number) => {
//   console.log(ModuleID);
//   console.log("UserID=" + sessionStorage.get("user_id"));
//   console.log(Array.isArray(rbacArray.UserModules)); // Should return true
//   console.log(typeof rbacArray.UserModules); // Should return 'object'

//   if (ModuleID && Array.isArray(rbacArray.UserModules)) {
//     const moduleAccess = rbacArray.UserModules.find(
//       (p: { ModuleId: number }) => p.ModuleId === ModuleID
//     );
//     if (moduleAccess) {
//       if (moduleAccess.Role.Permissions[0].delete) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };
const CheckModuleDeleteAccess = (ModuleID: number) => {
  // console.log("Checking delete access for ModuleID:", ModuleID);
  // console.log("UserID:", sessionStorage.get("user_id"));
  // console.log("Is UserModules an array?", Array.isArray(rbacArray.UserModules));
  // console.log("Type of UserModules:", typeof rbacArray.UserModules);

  if (ModuleID && Array.isArray(rbacArray?.UserModules)) {
    const moduleAccess = rbacArray?.UserModules.find(
      (p) => String(p.ModuleId) === String(ModuleID) // Ensure both sides are compared as strings
    );

    // console.log("Module Access Found:", moduleAccess); // Log found access

    if (moduleAccess) {
      // Check if Role and Permissions are defined
      const hasPermissions =
        moduleAccess.Role &&
        moduleAccess.Role.Permissions &&
        moduleAccess.Role.Permissions.length > 0;

      if (hasPermissions && moduleAccess.Role.Permissions[0]?.delete) {
        return true; // User has delete access
      } else {
        //  console.log ("User does not have delete access.");
        return false; // User does not have delete access
      }
    } else {
      // console.log("No access found for this ModuleID.");
      return false; // No access for this ModuleID
    }
  } else {
    // console.log("Invalid ModuleID or UserModules is not an array.");
    return false; // Invalid parameters
  }
};

export {
  Authorization,
  CheckUserModuleAccess, //updated
  CheckModuleCreateAccess, //updated
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
};
