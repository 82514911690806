import React, { useState, useEffect } from "react";
import "./MailSync.css";
import "../../GlobalCss/Module/ModuleView.css";
import { useModuleContext } from "../../Contexts/ModuleContext";
//
import { sessionStorage } from "es-storage";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//
//auth middleware
import { CheckModuleCreateAccess } from "../../middleware/authorizationMiddleware";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
// import UserTable from "./Tables/userTable";
//
//mui
//import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//

const MailSync = () => {
  //
  const navigate = useNavigate();
  //
  const location = useLocation();
  // const moduleId = location.state?.moduleId;
  // Extract module ID from state
  //const [moduleId, setModuleId] = useState(location.state?.moduleId);
  const { contextModuleId, moduleLink } = useModuleContext();
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userRole]);
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={() => {
              navigate(".");
            }}
          >
            MailSync
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <button
              className="moduleCreateBtn"
              onClick={() => {
                navigate("create");
              }}
            >
              New Mailing
            </button>
          ) : null}
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="create" element={<CreateView />} />
          <Route path="update" element={<UpdateView />} />
        </Routes>
      </div>
    </div>
  );
};

export default MailSync;
