import React, { useState, useEffect } from "react";
import "./WarehouseMng.css";
//notifications
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//api
import { Empty, Spin } from "antd";
import { Modal as AntdModal } from "antd";
//
import {
  addWarehouse,
  getAllWarehouse,
  deleteSingeleWarehouse,
  updateSingeleWarehouse,
} from "../../../API/warehouseAPI";
//mui model
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//
//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//
//

//
//

// Define interface for warehouse object
interface Warehouse {
  id: number;
  name: string;
  address: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

const WarehouseMng = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //
  const [warehouseData, setwarehouseData] = useState<Warehouse[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  // new warehouse states
  const [NewWarehouseName, setNewWarehouseName] = useState("");
  const [NewWarehouseAddress, setNewWarehouseAddress] = useState("");
  // edit warehouse states
  const [EditWarehouseID, setEditWarehouseID] = useState("");
  const [EditWarehouseName, setEditWarehouseName] = useState("");
  const [EditWarehouseAddress, setEditWarehouseAddress] = useState("");
  //new warehouse mui modal state
  const [openNewWarehouse, setOpenNewWarehouse] = React.useState(false);
  const handleOpenNewWarehouse = () => setOpenNewWarehouse(true);
  const handleCloseNewWareHouse = () => setOpenNewWarehouse(false);
  //edit warehouse mui modal state
  const [openEditWarehouse, setOpenEditWarehouse] = React.useState(false);
  const handleOpenEditWarehouse = () => setOpenEditWarehouse(true);
  const handleCloseEditWareHouse = () => setOpenEditWarehouse(false);
  //custom methods
  const HandleAddWarehouse = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      var req = await addWarehouse({
        Name: NewWarehouseName,
        Address: NewWarehouseAddress,
      });
      console.log(req);
      GetWarehouses();
      notifySuccess(req.data.msg);
    } catch (err: any) {
      notifyError(`${err.response.data}\n Status Code ${err.status}`);
      console.log(err);
    } finally {
      //spin
      setLoading(false);
      //
    }
  };

  const HandleEditWarehouse = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      //
      setLoading(true);
      //
      const req = await updateSingeleWarehouse({
        ID: EditWarehouseID,
        Name: EditWarehouseName,
        Address: EditWarehouseAddress,
      });
      // console.log(call);
      GetWarehouses();
      notifySuccess(req.data.msg);
    } catch (err: any) {
      console.log(err);
      notifyError(`${err.response.data}\n Status Code ${err.status}`);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const GetWarehouses = async () => {
    try {
      var req: any = await getAllWarehouse();
      setwarehouseData(req.data);
      console.log(req);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetWarehouses();
  }, []);
  //

  //mui
  //methods
  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const editedRow: any = warehouseData.find((row) => row.id === id);
    setEditWarehouseID(editedRow.id);
    setEditWarehouseName(editedRow.name);
    setEditWarehouseAddress(editedRow.address);
    handleOpenEditWarehouse();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  const handleDeleteClick = async (id: GridRowId) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleWarehouse({ id: id });
      console.log(call);
      GetWarehouses();
      notifySuccess(call.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data) {
      }
      if (err.response.data) {
        notifyError(err.response.data);
      }
    }
  };

  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    {
      field: "createdBy",
      valueGetter: (value, row) => {
        return `${row.CreatedByUser ? row.CreatedByUser.fullName : ""}`;
      },
      headerName: "Created By",
      width: 130,
    },
    { field: "createdAt", headerName: "Created At", width: 150 },
    {
      field: "updatedBy",
      valueGetter: (value, row) => {
        return `${row.UpdatedByUser ? row.UpdatedByUser.fullName : ""}`;
      },
      headerName: "Updated By",
      width: 130,
    },
    { field: "updatedAt", headerName: "Updated At", width: 150 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            //onClick={handleDeleteClick(id)}
            onClick={() => {
              AntdModal.confirm({
                title: "Confirm",
                content: "Are you sure you want to delete this record?",
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    {/* <Button>Custom Button</Button> */}
                    <CancelBtn />
                    <OkBtn />
                  </>
                ),
                onOk: () => {
                  handleDeleteClick(id);
                },
              });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="warehouse">
      <div className="ActionDiv">
        <button
          className="moduleCreateBtn"
          onClick={handleOpenNewWarehouse}
          onMouseEnter={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.backgroundColor = "#e0a800";
          }}
          onMouseLeave={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.backgroundColor = "#FFC107";
          }}
          onMouseDown={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.transform = "scale(0.95)";
          }}
          onMouseUp={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.transform = "scale(1)";
          }}
          style={{
            backgroundColor: "#FFC107",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            transition: "all 0.2s ease",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "4px",
          }}
        >
          New Warehouse
        </button>
      </div>

      <div className="warehouseDisplay"></div>
      {/* New warehouse mode */}
      <Modal
        open={openNewWarehouse}
        onClose={handleCloseNewWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              New Warehouse
            </h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleAddWarehouse}
                className="NewWarehouseForm"
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    {/* Warehouse Name */}
                    <label
                      className="NewWarehouseLabel"
                      style={{ fontWeight: "bold" }}
                    >
                      Name:
                      <input
                        type="text"
                        placeholder="Warehouse Name"
                        onChange={(event) =>
                          setNewWarehouseName(event.target.value)
                        }
                        minLength={2}
                        required
                        style={{
                          padding: "8px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>

                    {/* Warehouse Address */}
                    <label
                      className="NewWarehouseLabel"
                      style={{ fontWeight: "bold" }}
                    >
                      Address:
                      <textarea
                        placeholder="Warehouse Address Description"
                        onChange={(event) =>
                          setNewWarehouseAddress(event.target.value)
                        }
                        minLength={2}
                        style={{
                          padding: "8px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          minHeight: "100px",
                        }}
                      />
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    className="addbtn"
                    type="submit"
                    style={{
                      backgroundColor: "#007BFF",
                      color: "#fff",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontSize: "16px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      ((e.target as HTMLButtonElement).style.backgroundColor =
                        "#0056b3")
                    }
                    onMouseOut={(e) =>
                      ((e.target as HTMLButtonElement).style.backgroundColor =
                        "#007BFF")
                    }
                  >
                    Add Warehouse
                  </button>
                </div>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>

      {/*  */}

      {/* Edit warehouse mode */}
      <Modal
        open={openEditWarehouse}
        onClose={handleCloseEditWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              Edit Warehouse
            </h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleEditWarehouse}
                className="NewWarehouseForm"
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    {/* Warehouse Name */}
                    <label
                      className="NewWarehouseLabel"
                      style={{ fontWeight: "bold" }}
                    >
                      Name:
                      <input
                        type="text"
                        placeholder="Warehouse Name"
                        value={EditWarehouseName}
                        onChange={(event) =>
                          setEditWarehouseName(event.target.value)
                        }
                        minLength={2}
                        required
                        style={{
                          padding: "8px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>

                    {/* Warehouse Address */}
                    <label
                      className="NewWarehouseLabel"
                      style={{ fontWeight: "bold" }}
                    >
                      Address:
                      <textarea
                        placeholder="Warehouse Address Description"
                        value={EditWarehouseAddress}
                        onChange={(event) =>
                          setEditWarehouseAddress(event.target.value)
                        }
                        minLength={2}
                        style={{
                          padding: "8px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          minHeight: "100px",
                        }}
                      />
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    className="addbtn"
                    type="submit"
                    style={{
                      backgroundColor: "#28a745",
                      color: "#fff",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontSize: "16px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      ((e.target as HTMLButtonElement).style.backgroundColor =
                        "#218838")
                    }
                    onMouseOut={(e) =>
                      ((e.target as HTMLButtonElement).style.backgroundColor =
                        "#28a745")
                    }
                  >
                    Update Warehouse
                  </button>
                </div>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>

      {/*  */}

      {/* Material ui warehose table */}
      {warehouseData && warehouseData.length > 0 ? (
        <DataGrid
          rows={warehouseData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default WarehouseMng;
