import React, { useEffect, useState } from "react";
import "./MyTasks.css";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
// import { Draggable } from "./Draggable"; // Assuming you have a Draggable component
import { Draggable } from "./Draggable";
import { Droppable } from "./Droppable"; // Assuming you have a Droppable component
// ant design
import { Modal, Spin } from "antd";
// notification
import { notifyError,notifySuccess,notifyWarning } from "../../Components/Notifications/Notifications";
// api methods
import {
  addMyTask,
  getAllMyTasks,
  updateSingelMyTaskStatus,
  deleteMyTask,
} from "../../API/mytaskAPI";

// Define a custom Task type to ensure type safety
type Task = {
  id: string;
  TenantId: string;
  UserId: string;
  title: string;
  description: string;
  status: string; // You can make this more specific by using literals like 'Draft' | 'In Progress' | 'Completed' | 'Canceled'
};

const MyTasks = () => {
  // Spin state
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    GetMyTasks();
  }, []);

  const [NewTaskTitle, setNewTaskTitle] = useState("");
  const [NewTaskStatus, setNewTaskStatus] = useState("");
  const [NewTaskDes, setNewTaskDes] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (state: any) => {
    setNewTaskStatus(state);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await addMyTask({
      Title: NewTaskTitle,
      Description: NewTaskDes,
      Status: NewTaskStatus,
    });
    GetMyTasks(); // Refresh task list without reload
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const taskStates = ["Draft", "In Progress", "Completed", "Canceled", "Trash"];

  const [tasks, setTasks] = useState<Task[]>([]);

  // Handle the drag and drop
  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      tasks.map(async (task) => {
        if (task.id === active.id) {
          const updatedTask = { ...task, status: over.id as string };

          if (updatedTask.status !== "Trash") {
            // Call the API to update task status on the server
            updateSingelMyTaskStatus(task.id, updatedTask.status)
              .then((response) => {
                // Update the state after the successful update
                setTasks((prevTasks) =>
                  prevTasks.map((t) =>
                    t.id === task.id ? { ...t, status: updatedTask.status } : t
                  )
                );
                notifySuccess("Task updated successfully");
              })
              .catch((error) => {
                console.error("Error updating task:", error);
                notifyError("Error updating task");
              });
          } else if (updatedTask.status === "Trash") {
            // Call the API to update task status on the server
            await deleteMyTask(task.id)
              .then((response) => {
                // Update the state after the successful update
                GetMyTasks();
                setTasks((prevTasks) =>
                  prevTasks.map((t) =>
                    t.id === task.id ? { ...t, status: updatedTask.status } : t
                  )
                );
                notifySuccess("Task Deleted successfully");
              })
              .catch((error) => {
                console.error("Error updating task:", error);
                notifyError("Error deleting task");
              });
          }

          return updatedTask;
        }
        return task;
      });
    }
  };

  // Notification methods

  // Fetch tasks from the server
  const GetMyTasks = async () => {
    try {
      setLoading(true);
      var req: any = await getAllMyTasks();
      setTasks(req.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // Delete a task and update UI
  // e: React.MouseEvent
  const HandleDeleteTaskStatus = async (ID: string) => {
    // event.preventDefault();
    // e.stopPropagation(); // Stop drag interaction
    try {
      if (!ID) {
        return notifyWarning("Invalid ID");
      }
      // Call the delete API
      // alert(ID);
      await deleteMyTask(ID);
      notifySuccess("Task Deleted");
      GetMyTasks();

      // After deleting, remove the task from the state
      // setTasks((prevTasks) => prevTasks.filter((task) => task.id !== ID));
    } catch (error: any) {
      if (error.response) {
        notifyError(error.response.data);
      } else if (error.request) {
        notifyError(error.request);
      } else {
        notifyError(error.message);
      }
    }
  };

  return (
    <div className="my-tasks-container">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2 className="moduleName">My Tasks</h2>
        </div>
      </div>
      <Spin spinning={loading}>
        <DndContext onDragEnd={handleDragEnd}>
          <div className="task-board">
            {taskStates.map((state) => (
              <div key={state} className="column">
                <Droppable id={state}>
                  <div className="task-Container">
                    <div className="task-Container-head">
                      <h2>{state}</h2>
                      <button
                        className="add-task-btn"
                        onClick={() => showModal(state)}
                      >
                        +
                      </button>
                    </div>
                    <div className="tasks-list">
                      {tasks
                        .filter((task) => task.status === state)
                        .map((task) => (
                          <Draggable
                            key={task.id}
                            id={task.id}
                            title={task.title}
                            description={task.description}
                            onClick={() =>
                              console.log("Task clicked:", task.id)
                            }
                            onDelete={() => HandleDeleteTaskStatus(task.id)}
                          />
                        ))}
                      {tasks.filter((task) => task.status === state).length ===
                        0 && (
                        <p className="empty-column-text">Drop tasks here</p>
                      )}
                    </div>
                  </div>
                </Droppable>
              </div>
            ))}
          </div>
        </DndContext>
        <Modal
          title="New Task"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="custom-modal" // Modal class
        >
          <input
            type="text"
            placeholder="New Task Title"
            onChange={(e) => setNewTaskTitle(e.target.value)}
            required
            className="task-title-input" // Input field class
          />
          <textarea
            placeholder="What is the task?"
            onChange={(e) => setNewTaskDes(e.target.value)}
            required
            className="task-description-textarea" // Textarea class
          />
        </Modal>
      </Spin>
    </div>
  );
};

export default MyTasks;
