import React, { useEffect, useState } from "react";
import { Badge, Drawer, List, Typography, Button } from "antd";
import { BellOutlined } from "@ant-design/icons";
// import { io } from "socket.io-client";
// import { useAuthContext } from "../../hooks/authcontext";
import NotificationService from "../../services/notificationService";
//api controller
import { getAllNotification } from "../../API/notificationAPI";

interface Notification {
  id: number;
  message: string;
  type: "info" | "success" | "warning" | "error";
  title?: string;
  link?: string;
  read: boolean;
  createdAt: string;
}

export default function NotificationBell() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const { user } = useAuthContext();

  // const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        var req: any = await getAllNotification();
        setNotifications(req.data);
        console.log("debug");
        console.log(req.data);
      } catch (err) {
        console.log(err);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const toggleDrawer = () => {
    console.log("Toggle drawer called, current state:", drawerVisible);
    setDrawerVisible(!drawerVisible);
  };

  useEffect(() => {
    console.log("Drawer visibility changed to:", drawerVisible);
  }, [drawerVisible]);

  // useEffect(() => {
  //   // Get user ID from session storage if not available in context
  //   const userId = user?.id || sessionStorage.getItem("user_id");
  //   if (!userId) return;

  //   // Connect to WebSocket
  //   const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:3014');
  //   socket.emit('joinNotificationRoom', userId);

  //   // Listen for new notifications
  //   socket.on('newNotification', (notification: Notification) => {
  //     setNotifications(prev => [notification, ...prev]);
  //     setUnreadCount(prev => prev + 1);
  //   });

  //   // Fetch existing notifications
  //   const fetchNotifications = async () => {
  //     try {
  //       const response = await NotificationService.getNotifications(parseInt(userId));
  //       setNotifications(response.notifications);
  //       const unreadNotifications = response.notifications.filter(
  //         (n: Notification) => !n.read
  //       ).length;
  //       setUnreadCount(unreadNotifications);
  //     } catch (error) {
  //       console.error('Error fetching notifications:', error);
  //     }
  //   };

  //   fetchNotifications();

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [user?.id]);

  const handleMarkAsRead = async (notificationId: number) => {
    try {
      await NotificationService.markAsRead(notificationId);
      setNotifications((prev) =>
        prev.map((n) => (n.id === notificationId ? { ...n, read: true } : n))
      );
      setUnreadCount((prev) => Math.max(0, prev - 1));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    // const userId = user?.id || sessionStorage.getItem("user_id");
    const userId = sessionStorage.getItem("user_id");
    if (!userId) return;

    try {
      await NotificationService.markAllAsRead(parseInt(userId));
      setNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const getNotificationColor = (type: string) => {
    switch (type) {
      case "success":
        return "#52c41a";
      case "warning":
        return "#faad14";
      case "error":
        return "#f5222d";
      default:
        return "#1890ff";
    }
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Badge count={unreadCount} offset={[-5, 5]}>
        <BellOutlined
          style={{
            fontSize: "24px",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={toggleDrawer}
        />
      </Badge>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Text strong>Notifications</Typography.Text>
            {unreadCount > 0 && (
              <Button type="link" onClick={handleMarkAllAsRead}>
                Mark all as read
              </Button>
            )}
          </div>
        }
        placement="right"
        onClose={toggleDrawer}
        open={drawerVisible}
        width={400}
      >
        <List
          dataSource={notifications}
          renderItem={(notification: Notification) => (
            <List.Item
              style={{
                opacity: notification.read ? 0.7 : 1,
                cursor: "pointer",
                padding: "12px",
                borderLeft: `4px solid ${getNotificationColor(
                  notification.type
                )}`,
                backgroundColor: notification.read ? "transparent" : "#f0f2f5",
                marginBottom: "8px",
                borderRadius: "4px",
              }}
              onClick={() =>
                !notification.read && handleMarkAsRead(notification.id)
              }
            >
              <List.Item.Meta
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography.Text strong>
                      {notification.title || "Notification"}
                    </Typography.Text>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {new Date(notification.createdAt).toLocaleDateString()}
                    </Typography.Text>
                  </div>
                }
                description={
                  <Typography.Paragraph ellipsis={{ rows: 2 }}>
                    {notification.message}
                  </Typography.Paragraph>
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: "No notifications" }}
        />
      </Drawer>
    </div>
  );
}

// export default NotificationBell;
