import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");

const getExchangeRates = async () => {
  try {
    // var req = await axios.get(`${process.env.REACT_APP_GET_EXCHANGE_RATES}`, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // return req;
  } catch (err: any) {
    throw err;
  }
};

export { getExchangeRates };
