import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addMailSync = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_MAILSYNC_ADD_ONE}`,
      {
        subject: props.Subject,
        bodyHtml: props.BodyHtml,
        bodyJson: props.BodyJson,
        bodyPlane: props.BodyPlane,
        CreatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllMailSync = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_MAILSYNC_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getSingleMail = async (RecordID: Number) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_MAILSYNC_GET_ONE}/${RecordID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
  
    throw err;
  }
};

const updateSingeleMailSync = async (props: any) => {

  try {
    var req = await axios.put(
      `${process.env.REACT_APP_MailSync_UPDATE_ONE}/${props.ID}`,
      {
        subject: props.Subject,
        bodyHtml: props.BodyHtml,
        bodyJson: props.BodyJson,
        bodyPlane: props.BodyPlane,
        UpdatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleMailSync = async (props: any) => {

  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_MAILSYNC_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addMailSync,
  getAllMailSync,
  getSingleMail,
  updateSingeleMailSync,
  deleteSingeleMailSync,
};
