import React from "react";
//ant design
import { Col, Row } from "antd";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
//

const ListView = () => {
  //
  const navigate = useNavigate();
  //
  return (
    <div>
      {" "}
      <Row>
        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          <Card
            title="Payment Terms"
            bordered={true}
            onClick={() => {
              navigate("./payment_term");
            }}
          >
            Card content
          </Card>
        </Col>
        <Col xs={20} sm={16} md={12} lg={8} xl={4}>
          <Card title="Currency" bordered={true}>
            Card content
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ListView;
