import React from "react";
import "./DashboardView.css"; //Local

///ui
//antdesign
//icons
import { SmileOutlined } from "@ant-design/icons";
import { Skeleton, Result, Button } from "antd";
//

const DashboardView = () => {
  return (
    <div className="DashboardView">
      <Result
        icon={<SmileOutlined />}
        status="info"
        title="Coming Soon!"
        subTitle="We're working hard to bring this feature to you. Stay tuned!"
      />
    </div>
  );
};

export default DashboardView;
