import React from "react";
import "./Settings.css";
import { Routes, Route, NavLink } from "react-router-dom";
//pages
import GeneralSetting from "./GeneralSetting/GeneralSetting";
import SecuritySetting from "./SecuritySetting/SecuritySetting";
import InventorySetting from "./InventorySetting/InventorySetting";
import Setting5 from "./Setting5/Setting5";
//icont

const Settings = () => {
  //
  // const location = useLocation();
  // const navigate = useNavigate();
  // Use useLocation hook to get the current location
  // const location = useLocation();
  return (
    <div className="settings">
      <div className="settingNav">
        <ul>
          <li>
            <NavLink to="./" end>
              <span className="icon icon1"></span>
              General
            </NavLink>
          </li>
          <li>
            <NavLink to="inventory_settings">
              <span className="icon icon1"></span>
              Inventory
            </NavLink>
          </li>
          <li>
            <NavLink to="security_setting">
              <span className="icon icon1"></span>
              Security
            </NavLink>
          </li>
          <li>
            <NavLink to="Setting5">
              <span className="icon icon1"></span>
              Email
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="settingDisplay">
        <Routes>
          <Route index path="/" element={<GeneralSetting />}></Route>
          <Route path="security_setting" element={<SecuritySetting />} />
          <Route path="inventory_settings" element={<InventorySetting />} />
          <Route path="Setting5" element={<Setting5 />} />
        </Routes>
      </div>
    </div>
  );
};

export default Settings;
