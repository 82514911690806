import React from "react";
import { sessionStorage } from "es-storage";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#ffffff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#007bff",
    paddingBottom: 10,
  },
  headerText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  companyDetails: {
    fontSize: 10,
    marginBottom: 5,
  },
  logo: {
    width: 60,
    height: 60,
    marginLeft: "auto",
  },
  clientSection: {
    marginBottom: 20,
  },
  clientDetails: {
    fontSize: 10,
    marginBottom: 5,
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    flex: 1,
    fontSize: 10,
    fontWeight: "bold",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
    backgroundColor: "#f2f2f2",
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
  },
  totalSection: {
    marginTop: 10,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
  },
  footer: {
    marginTop: 20,
    borderTopWidth: 2,
    borderTopColor: "#007bff",
    paddingTop: 10,
    fontSize: 10,
    textAlign: "center",
  },
});

// Create Document Component
const MyQuotationDocument = () => {
  const companyName = sessionStorage.get("company_name") || "Your Company Name";
  const companyAddress =
    sessionStorage.get("company_address") || "Your Address";
  const companyEmail =
    sessionStorage.get("company_email") || "email@example.com";
  const companyLogo = sessionStorage.get("company_logo") || null; // Add default logo if needed

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View>
            <Text style={styles.headerText}>Sales Quotation</Text>
            <Text style={styles.companyDetails}>
              Company Name: {companyName}
            </Text>
            <Text style={styles.companyDetails}>Address: {companyAddress}</Text>
            <Text style={styles.companyDetails}>Phone: +251 123 456 789</Text>
            <Text style={styles.companyDetails}>Email: {companyEmail}</Text>
          </View>
          {companyLogo && <Image style={styles.logo} src={companyLogo} />}
        </View>

        {/* Client Details */}
        <View style={styles.clientSection}>
          <Text style={styles.clientDetails}>Client Name: John Doe</Text>
          <Text style={styles.clientDetails}>
            Client Address: 456 Elm St, Addis Ababa, Ethiopia
          </Text>
          <Text style={styles.clientDetails}>
            Client Phone: +251 987 654 321
          </Text>
          <Text style={styles.clientDetails}>Quotation Date: 2025-01-19</Text>
        </View>

        {/* Quotation Items Table */}
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>#</Text>
            <Text style={styles.tableCellHeader}>Item Name</Text>
            <Text style={styles.tableCellHeader}>Quantity</Text>
            <Text style={styles.tableCellHeader}>Unit Price</Text>
            <Text style={styles.tableCellHeader}>Total</Text>
          </View>

          {/* Table Rows (Example Items) */}
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>1</Text>
            <Text style={styles.tableCell}>Product A</Text>
            <Text style={styles.tableCell}>2</Text>
            <Text style={styles.tableCell}>500 ETB</Text>
            <Text style={styles.tableCell}>1000 ETB</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>2</Text>
            <Text style={styles.tableCell}>Product B</Text>
            <Text style={styles.tableCell}>1</Text>
            <Text style={styles.tableCell}>800 ETB</Text>
            <Text style={styles.tableCell}>800 ETB</Text>
          </View>
        </View>

        {/* Total Section */}
        <View style={styles.totalSection}>
          <Text>Total: 1800 ETB</Text>
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>Thank you for your business!</Text>
          <Text>Terms: Payment is due within 30 days.</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyQuotationDocument;
