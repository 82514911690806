import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
//pages
import ListView from "./ListView/ListView";
import PaymentTerm from "./PaymentTerm/PaymentTerm";

//

const Accounting = () => {
  //
  const navigate = useNavigate();
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={() => {
              navigate("./");
            }}
            style={{
              cursor: "pointer",
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Accounting
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          <div className="moduleMngActionBtns">{/* Comming Soon! */}</div>
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="/payment_term/*" element={<PaymentTerm />} />
        </Routes>
      </div>
    </div>
  );
};

export default Accounting;
