import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";

////Access Controle Middle Wares
import {
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../../middleware/authorizationMiddleware";
//
// api methods
import {
  getAllProduct,
  deleteSingeleProduct,
} from "../../../../API/productAPI";
// notification
import {
  notifyError,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//
//ant design
import { Empty } from "antd";
import { Spin } from "antd";
import { Modal as AntdModel } from "antd";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";
//mui icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useModuleContext } from "../../../../Contexts/ModuleContext";
// Define interface for product object
interface Product {
  id: number;
  internalref: string; // If your data includes an internal reference
  name: string;
  model: string;
  brand_name: string;
  document: string;
  description: string;
  image: string;
  quantity: number;
  cost_price: number;
  sales_price: number;
  purchase_method: string;
  ProductCategorieId?: number;
  ProductCategorieUUID?: string;
  ProductSubCategorieId?: number;
  ProductSubCategorieUUID?: string;
  SupplierId?: number;
  WarehouseId?: number;
  ProductCategorie?: {
    uuid: string;
    name: string;
  };
  ProductSubCategorie?: {
    uuid: string;
    name: string;
  };
  Supplier?: {
    name: string;
  };
  Warehouse?: {
    name: string;
  };
  Product_Warehouses?: Array<{
    quantity: number;
  }>;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

const ListView = () => {
  //
  const { contextModuleId, moduleLink } = useModuleContext();
  // const moduleid = location.state?.moduleId;
  // console.log(moduleid);
  const navigate = useNavigate();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //mui table state
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //product states
  const [productData, setproductData] = useState<Product[]>([]);

  const handleExportToPDF = () => {
    const doc = new jsPDF();
    const exportData = productData.map((product) => [
      product.id,
      product.internalref,
      product.name,
      product.model,
      product.brand_name,
      product.cost_price,
      product.sales_price,
      product.Product_Warehouses
        ? product.Product_Warehouses.reduce(
            (sum, warehouse) => sum + warehouse.quantity,
            0
          )
        : 0,
      product.Supplier ? product.Supplier.name : "",
      product.Warehouse ? product.Warehouse.name : "",
      product.createdAt,
    ]);

    const headers = [
      "ID",
      "Internal Ref",
      "Product Name",
      "Model",
      "Brand Name",
      "Cost Price",
      "Sale Price",
      "Quantity",
      "Supplier",
      "Warehouse",
      "Created At",
    ];

    doc.text("Products List", 14, 10);
    // doc.autoTable({
    //   head: [headers],
    //   body: exportData,
    //   startY: 20,
    // });

    doc.save("ProductsList.pdf");
  };

  //   Model methods
  const GetProducts = async () => {
    try {
      var req: any = await getAllProduct();
      setproductData(req.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };

  useEffect(() => {
    GetProducts();
  }, []);

  //   mui data grid methods
  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      const call = await deleteSingeleProduct({ id: id });
      console.log(call);
      GetProducts();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  const handleExportToExcel = () => {
    const exportData = productData.map((product) => ({
      ID: product.id,
      "Internal Ref": product.internalref,
      "Product Name": product.name,
      Model: product.model,
      "Brand Name": product.brand_name,
      "Cost Price": product.cost_price,
      "Sale Price": product.sales_price,
      Quantity: product.Product_Warehouses
        ? product.Product_Warehouses.reduce(
            (sum, warehouse) => sum + warehouse.quantity,
            0
          )
        : 0,
      Supplier: product.Supplier ? product.Supplier.name : "",
      Warehouse: product.Warehouse ? product.Warehouse.name : "",
      CreatedAt: product.createdAt,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    XLSX.writeFile(workbook, "ProductsList.xlsx");
  };

  //mui data Columns
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "internalref", headerName: "Internal Ref", width: 100 },
    { field: "name", headerName: "Product Name", width: 130 },
    { field: "model", headerName: "Model", width: 130 },
    { field: "brand_name", headerName: "Brand Name", width: 130 },
    { field: "document", headerName: "Product Doc", width: 130 },
    { field: "description", headerName: "Product Description", width: 130 },
    { field: "image", headerName: "Product Image", width: 130 },
    {
      field: "quantity",
      valueGetter: (value, row) => {
        function sumQuantity() {
          let sum = 0;
          if (row.Product_Warehouses) {
            for (let i = 0; i < row.Product_Warehouses.length; i++) {
              sum += row.Product_Warehouses[i].quantity;
            }
          }
          return sum;
        }
        return `${sumQuantity()}`;
      },
      headerName: "On Hand",
      width: 80,
    },
    { field: "cost_price", headerName: "Cost Price", width: 100 },
    { field: "sales_price", headerName: "Sale Price", width: 100 },
    { field: "purchase_method", headerName: "Purchase Method", width: 100 },
    {
      field: "ProductCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductCategorieUUID",
      type: "string",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.uuid : ""}`;
      },
      headerName: "PC UUID",
      width: 100,
    },
    {
      field: "ProductSubCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductSubCategorieUUID",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.uuid : ""}`;
      },
      headerName: "ProductSubCategorieUUID",
      width: 100,
    },
    { field: "SupplierId", headerName: "Supplier ID", width: 70 },
    { field: "WarehouseId", headerName: "Warehouse ID", width: 70 },
    {
      field: "ProductCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.name : ""}`;
      },
      headerName: "Categorie",
      width: 100,
    },
    {
      field: "ProductSubCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.name : ""}`;
      },
      headerName: "SubCategorie",
      width: 100,
    },

    {
      field: "Supplier",
      valueGetter: (value, row) => {
        return `${row.Supplier ? row.Supplier.name : ""}`;
      },
      headerName: "Supplier",
      width: 100,
    },
    {
      field: "Warehouse",
      valueGetter: (value, row) => {
        return `${row.Warehouse ? row.Warehouse.name : ""}`;
      },
      headerName: "Warehouse",
      width: 100,
    },
    {
      field: "Product_Warehouses",
      valueGetter: (value, row) => {
        return `${row.Product_Warehouses ? row.Product_Warehouses : ""}`;
      },
      headerName: "Product Warehouse",
      width: 100,
    },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Last Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() =>
                    navigate(`update`, { state: { routeRecordId: id } })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  //onClick={handleDeleteClick(id)}
                  onClick={() => {
                    AntdModel.confirm({
                      title: "Confirm",
                      content: "Are you sure you want to delete this record?",
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          {/* <Button>Custom Button</Button> */}
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: () => {
                        async function asyncDeleteCall() {
                          await handleDeleteClick(id);
                        }
                        asyncDeleteCall();
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];
  //

  return (
    <div>
      <Spin spinning={loading}>
        <div style={{ marginBottom: "16px", display: "flex", gap: "8px" }}>
          <button
            onClick={handleExportToExcel}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              padding: "8px",
              cursor: "pointer",
              borderRadius: "50%",
            }}
          >
            <AiOutlineFileExcel size={24} />
          </button>
          <button
            onClick={handleExportToPDF}
            style={{
              backgroundColor: "#E74C3C",
              color: "white",
              border: "none",
              padding: "8px",
              cursor: "pointer",
              borderRadius: "50%",
            }}
          >
            <AiOutlineFilePdf size={24} />
          </button>
        </div>
        {productData && productData.length > 0 ? (
          <DataGrid
            rows={productData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  image: false,
                  createdAt: false,
                  createdBy: false,
                  updatedAt: false,
                  updatedBy: false,
                  Product_Warehouses: false,
                  SupplierId: false,
                  WarehouseId: false,
                  Warehouse: false,
                  description: false,
                  document: false,
                  purchase_method: false,
                  ProductCategorieId: false,
                  ProductCategorieUUID: false,
                  ProductSubCategorieId: false,
                  ProductSubCategorieUUID: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default ListView;
