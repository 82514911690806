import React, { useRef, useState } from "react";
//notification
import {
  notifySuccess,
  notifyError,
} from "../../../Components/Notifications/Notifications";
//
//mail builder
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
//reach text editor
import ReactQuill from "react-quill";
//antdesign
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Html } from "@mui/icons-material";

//Api END POINTS
import { addMailSync } from "../../../API/mailSyncAPI";

const CreateView = () => {
  const [NewSubject, setNewSubject] = useState("");
  const [NewRecipients, setNewRecipients] = useState("");
  const [NewBodyHtml, setNewBodyHtml] = useState<String>();
  const [NewBodyJson, setNewBodyJson] = useState({});
  const [RecordNote, setRecordNote] = useState("");
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);

  //email builder
  const emailEditorRef = useRef<EditorRef>(null);
  console.log(emailEditorRef);

  const exportHtml = async () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      console.log(design);
      setNewBodyHtml(html);
      setNewBodyJson(design);
    });
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);

    //Auto save
    unlayer.addEventListener("design:updated", function (updates: any) {
      // Design has been updated by the user

      unlayer.exportHtml(function (data) {
        var json = data.design; // The updated design JSON
        var jsonString = JSON.stringify(json); // Convert object to JSON string
        const parsed = JSON.parse(jsonString); // Parse JSON string back to object
        var html = data.html; // The updated HTML
        setNewBodyHtml(html);
        setNewBodyJson(parsed);

        // Auto-save the JSON and/or HTML here
      });
    });
  };

  //Model Methods
  const handleCreateRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      await exportHtml();
      //
      //alert(NewProductWarehouseID);

      var req = await addMailSync({
        Subject: NewSubject,
        BodyHtml: NewBodyHtml,
        BodyJson: NewBodyJson,
        Record_Note: RecordNote,
      });
      console.log(req);
      //GetProducts();
      notifySuccess(req.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      }

      if (err.response.statusText) {
        notifyError(err.response.statusText);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //
  return (
    <div className="ModuleCreateView">
      <form className="ModuleCreateViewForm" onSubmit={handleCreateRecord}>
        <div className="form-container">
          <div className="form-header">
            <h4>New Mailing Form</h4>
            <button className="moduleSaveBtn" type="submit">
              Save
            </button>
          </div>

          <div className="form-row">
            <label className="productlabels">
              Subject:
              <input
                type="text"
                placeholder="New Report"
                onChange={(event) => setNewSubject(event.target.value)}
                min={1}
                required
              />
            </label>
          </div>

          <div className="form-row">
            <label className="productlabels">
              Recipients
              <input
                type="text"
                placeholder="New Report"
                onChange={(event) => setNewRecipients(event.target.value)}
                min={1}
                required
              />
            </label>
          </div>

          <div className="form-row">
            <label className="productlabels">
              Mail Body
              {/* <div>
                <button onClick={exportHtml}>Export HTML</button>
              </div> */}
              <EmailEditor ref={emailEditorRef} onReady={onReady} />
            </label>
          </div>
          <div className="form-row">
            <ReactQuill theme="snow" onChange={setRecordNote} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateView;
