import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addProductCategories = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_PRODUCTCATEGORY_ADD_ONE}`,
      {
        name: props.Name,
        uuid: props.UUID,
        description: props.Description,
        CreatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllProductCategories = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_PRODUCTCATEGORY_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleProductCategory = async (props: any) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_PRODUCTCATEGORY_DELETEONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingeleProductCategory = async (props: {
  ID: number;
  UUID: String | number;
  Name: String;
  Description: String;
}) => {
  // alert(props.id)
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_PRODUCTCATEGORY_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        uuid: props.UUID,
        description: props.Description,
        UpdatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addProductCategories,
  getAllProductCategories,
  deleteSingeleProductCategory,
  updateSingeleProductCategory,
};
