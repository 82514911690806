import React from "react";
//
import { Route, Routes, useNavigate } from "react-router-dom";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
//

const Quotation = () => {
  //
  const navigate = useNavigate();
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
        </Routes>
      </div>
    </div>
  );
};

export default Quotation;
