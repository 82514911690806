import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");


const getAllNotification = async () => {
  try {
    if(!current_user_id){
      throw new Error("Invalid User ID");
    }
    // var req = await axios.get(`${process.env.REACT_APP_Notification_GET_ALL}/${current_user_id}`, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // return req;
  } catch (err: any) {
    //console.log(err);
    throw err;
  }
};



export {
 
  getAllNotification,
  
};
