import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_id = sessionStorage.get("user_id");

const getAllProductTransfer = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_PRODUCT_TRANSFER_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const moveProduct = async (props: {
  PWID: number;
  ProductID: number;
  FromWarehoseID: number;
  ToWarehoseID: number;
  Quanity: number;
  MoveByID: number;
  // ApprovedById: number;
}) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_PRODUCT_TRANSFER_Move_ONE}/${props.PWID}`,
      {
        productID: props.ProductID,
        fromWarehoseID: props.FromWarehoseID,
        toWarehoseID: props.ToWarehoseID,
        quantity: props.Quanity,
        movedbyID: props.MoveByID,
        approvedbyID: current_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(req);
    return req;
  } catch (err: any) {
    throw err;
  }
};

export { getAllProductTransfer, moveProduct };
