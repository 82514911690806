import React, { createContext, useContext, useState, useEffect } from "react";

// Define the module data structure
interface ModuleContextType {
  contextModuleId: number;
  moduleLink: string;
  setModuleData: (id: number, link: string) => void;
}

// Create the context
const ModuleContext = createContext<ModuleContextType | undefined>(undefined);

// Custom hook to use the Module context
export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  if (!context) {
    throw new Error("useModuleContext must be used within a ModuleProvider");
  }
  return context;
};

// Provider component
export const ModuleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [contextModuleId, setContextModuleId] = useState<number>(
    parseInt(localStorage.getItem("contextModuleId") || "0", 10)
  );
  const [moduleLink, setModuleLink] = useState<string>(
    localStorage.getItem("moduleLink") || ""
  );

  // Effect to update local storage whenever moduleId or moduleLink changes
  useEffect(() => {
    if (contextModuleId) {
      localStorage.setItem("contextModuleId", contextModuleId.toString());
    }
    if (moduleLink) {
      localStorage.setItem("moduleLink", moduleLink);
    }
  }, [contextModuleId, moduleLink]);

  const setModuleData = (id: number, link: string) => {
    setContextModuleId(id);
    setModuleLink(link);
  };

  return (
    <ModuleContext.Provider
      value={{ contextModuleId, moduleLink, setModuleData }}
    >
      {children}
    </ModuleContext.Provider>
  );
};
