import * as React from "react";
import "./ListView.css";
import "../../../GlobalCss/Module/ListView/ListView.css";
import { useLocation, useNavigate } from "react-router-dom";
// contexts
import { useModuleContext } from "../../../Contexts/ModuleContext";
import { useTenantSubscriptionContext } from "../../../Contexts/TenantSubscriptionContext";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//Integrations
import { botDirectMessage } from "../../../Integration/Telegram/API";
//notifications
import {
  notifySuccess,
  notifyError,
} from "../../../Components/Notifications/Notifications";
// Ant Design
import { Button, Empty } from "antd";
import { Input } from "antd";

import { Spin } from "antd";
import { Button as AntButton } from "antd";
//ant design icons
import { MessageTwoTone } from "@ant-design/icons";
//
import { useEffect, useState, useCallback } from "react";
//ant design
import { Modal } from "antd";
//mui
import clsx from "clsx";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
//api methods
import {
  getAllUser,
  deleteSingeleUser,
  softDeleteSingeleUser,
} from "../../../API/userAPI";
//MUI
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//
import {
  // GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  // GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  GridRowId,
  // GridRowModel,
  // GridRowEditStopReasons,
  // GridSlots,
} from "@mui/x-data-grid";
import { CheckTenantTelegramModuleAccess } from "../../../middleware/tenantModuleAccess";

//

// Define interface for product object
interface User {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  role: string;
}

export default function ListView() {
  //
  const { TextArea } = Input;
  //
  const { modules, addModule } = useTenantSubscriptionContext();
  //Telegram modal states
  const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
  const [TelegramModaID, setTelegramModaID] = useState("");
  const [TelegramModaMessage, setTelegramModaMessage] = useState("");

  const showTelegramModal = (RowID: GridRowId) => {
    const RowData: any = ListViewDate.find((row) => row.id === RowID);
    const userTelegramID = RowData.telegramID;
    setTelegramModaID(userTelegramID);
    setIsTelegramModalOpen(true);
  };

  const handleTelegramModalOk = async () => {
    try {
      await botDirectMessage(TelegramModaID, TelegramModaMessage);
      notifySuccess("");
      setIsTelegramModalOpen(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        // console.log(error.request);
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message || "An unexpected error occurred.");
        //console.log("Error", error.message);
      } else {
        notifyError(error || "An unexpected error occurred.");
        alert(error);
        // console.log("Error", error); // Log the entire error for debugging
      }
    }
  };

  const handleTelegramModalCancel = () => {
    setTelegramModaID("");
    setTelegramModaMessage("");
    setIsTelegramModalOpen(false);
  };
  //
  const navigate = useNavigate();
  // const location = useLocation();
  const { contextModuleId, moduleLink } = useModuleContext();
  //const moduleid = location.state?.moduleId;
  // const [moduleid, setModuleId] = useState(location.state?.moduleId);

  // useEffect(() => {
  //   if (!moduleid && location.state?.moduleId) {
  //     setModuleId(location.state.moduleId);
  //   }
  // }, [location.state, moduleid]);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const [ListViewDate, setListViewDate] = useState<User[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };

  //custom methods

  const GetUsers = useCallback(async () => {
    try {
      setLoading(true);
      var req: any = await getAllUser();
      setListViewDate(req.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, []); // No dependencies needed for useCallback as it's not dependent on any external variables

  useEffect(() => {
    GetUsers();
  }, [GetUsers]); // Dependency is now the memoized GetUsers function, which won't change on re-renders

  // mui grid Methods
  const handleSoftDelete = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      if (!id) {
        throw Error("No Record ID");
      }
      const call = await softDeleteSingeleUser(id);
      //console.log(call);
      GetUsers();
      notifySuccess(call.data || "");
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        // console.log(error.request);
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message || "An unexpected error occurred.");
        //console.log("Error", error.message);
      } else {
        notifyError(error || "An unexpected error occurred.");
        alert(error);
        console.log("Error", error); // Log the entire error for debugging
      }
    }
  };

  const handleDeleteClick = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      if (!id) {
        throw Error("No Record ID");
      }
      const call = await deleteSingeleUser(id);
      //console.log(call);
      GetUsers();
      notifySuccess(call.data || "");
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        // console.log(error.request);
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message || "An unexpected error occurred.");
        //console.log("Error", error.message);
      } else {
        notifyError(error || "An unexpected error occurred.");
        alert(error);
        console.log("Error", error); // Log the entire error for debugging
      }
    }
  };
  //
  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fullName", headerName: "Name", width: 130 },
    {
      field: "status",
      type: "string",
      headerName: "Status",
      width: 130,
      cellClassName: (params: GridCellParams<any, String>) => {
        if (params.value == null) {
          return "";
        }

        return clsx("super-app", {
          negative: params.value === "soft_deleted",
          positive: params.value === "active",
        });
      },
    },
    { field: "email", headerName: "Email", width: 130 },
    { field: "phone", headerName: "Phone", width: 130 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    { field: "role", headerName: "Role", width: 130 },
    CheckTenantTelegramModuleAccess(modules)
      ? { field: "telegramID", headerName: "TG ID", width: 130 }
      : { field: "", headerName: "" },
    { field: "last_login", headerName: "Last Login", width: 130 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  onClick={() =>
                    navigate(`../update`, { state: { routeUserId: id } })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckTenantTelegramModuleAccess(modules) ? (
                <GridActionsCellItem
                  icon={<MessageTwoTone />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => showTelegramModal(id)}
                  color="inherit"
                />
              ) : (
                <></>
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm",
                      content: (
                        <div>
                          <p>Are you sure you want to delete this record?</p>
                          <p>
                            <strong>Soft Delete</strong>: The record will be
                            marked as deleted, but it will still exist in the
                            system and can be restored later.
                          </p>
                          <p>
                            <strong>Hard Delete</strong>: The record will be
                            permanently removed from the system and cannot be
                            recovered.
                          </p>
                        </div>
                      ),
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          <CancelBtn />
                          {/* <OkBtn /> */}
                          <AntButton
                            type="primary"
                            color="danger"
                            onClick={() => handleDeleteClick(id)}
                          >
                            Hard Delete
                          </AntButton>
                          <AntButton
                            type="primary"
                            color="primary"
                            onClick={() => handleSoftDelete(id)}
                          >
                            Soft Delete
                          </AntButton>
                        </>
                      ),
                      onOk: () => {
                        async function asyncDeleteCall() {
                          await handleDeleteClick(id);
                        }
                        asyncDeleteCall();
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];

  // /////////////////////////////////////////////////
  return (
    <div className="ListView">
      {/*  */}
      <Spin spinning={loading}>
        <div className="ListView_Header">
          <div className="ListView_Header_Actions">
            {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
              <button
                className="moduleCreateBtn"
                onClick={() => {
                  navigate("../create");
                }}
              >
                New User
              </button>
            ) : null}
          </div>
        </div>
        <div className="ListView_Table">
          {ListViewDate && ListViewDate.length > 0 ? (
            <Box
              sx={{
                height: 400,
                width: "100%",
                "& .super-app-theme--cell": {
                  backgroundColor: "rgba(224, 183, 60, 0.55)",
                  color: "#1a3e72",
                  fontWeight: "600",
                },
                "& .super-app.negative": {
                  backgroundColor: "rgba(157, 255, 118, 0.49)",
                  color: "#1a3e72",
                  fontWeight: "600",
                },
                "& .super-app.positive": {
                  backgroundColor: "#d47483",
                  color: "#1a3e72",
                  fontWeight: "600",
                },
              }}
            >
              <DataGrid
                className="UserMngDataGrid"
                rows={ListViewDate}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      id: false,
                      rbac_Customer: false,
                      rbac_Dashboard: false,
                      // rbac_Discuss: false,
                      rbac_Employee: false,
                      rbac_Inventory: false,
                      rbac_Supplier: false,
                      rbac_Sales: false,
                    },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            </Box>
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
      {/* Modals */}
      <Modal
        title="Telegram Simple Message"
        open={isTelegramModalOpen}
        onOk={handleTelegramModalOk}
        onCancel={handleTelegramModalCancel}
      >
        <p>Telegram ID: {TelegramModaID}</p>
        <TextArea
          rows={4}
          placeholder="Your Short Telegram Message"
          maxLength={100}
          onChange={(e) => setTelegramModaMessage(e.target.value)}
        />
      </Modal>
    </div>
  );
}
