import React, { useState, useEffect } from "react";
import "./ProductCategorie.css";
//Ant Design
import { Empty, Spin } from "antd";
import { Modal as AntdModal } from "antd";
// notifications
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//
//api
import {
  addProductCategories,
  getAllProductCategories,
  deleteSingeleProductCategory,
  updateSingeleProductCategory,
} from "../../../API/productcategoriesAPI";
//mui model
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
//
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//
//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//
//

//
//

// Define interface for warehouse object
interface Productcategorie {
  id: number;
  name: string;
  discription: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

const ProductCategorie = () => {
  //
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //
  const [productcategorieData, setProductCategorieData] = useState<
    Productcategorie[]
  >([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //new ProductCategori states
  const [NewProductCategorieName, setNewProductCategorieName] = useState("");
  const [NewProductCategorieUUID, setNewProductCategorieUUID] = useState("");
  const [NewProductCategorieDiscription, setNewProductCategorieDiscription] =
    useState("");
  //edit ProductCategori states
  const [EditProductCategorieID, setEditProductCategorieID] = useState("");
  const [EditProductCategorieName, setEditProductCategorieName] = useState("");
  const [EditProductCategorieUUID, setEditProductCategorieUUID] = useState("");
  const [EditProductCategorieDiscription, setEditProductCategorieDiscription] =
    useState("");
  //new warehouse mui modal state
  const [openNewWarehouse, setOpenNewWarehouse] = React.useState(false);
  const handleOpenNewWarehouse = () => setOpenNewWarehouse(true);
  const handleCloseNewWareHouse = () => setOpenNewWarehouse(false);
  //edit warehouse mui modal state
  const [openEditWarehouse, setOpenEditWarehouse] = React.useState(false);
  const handleOpenEditWarehouse = () => setOpenEditWarehouse(true);
  const handleCloseEditWareHouse = () => setOpenEditWarehouse(false);
  //custom methods
  const HandleAddProductCategorie = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      var req = await addProductCategories({
        Name: NewProductCategorieName,
        UUID: NewProductCategorieUUID,
        Description: NewProductCategorieDiscription,
      });
      console.log(req);
      //HandleGetProductCategories();
      notifySuccess(req.data);
    } catch (err: any) {
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //

  const HandleUpdateProductCategory = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      //
      setLoading(true);
      //
      const req = await updateSingeleProductCategory({
        ID: EditProductCategorieID as unknown as number,
        Name: EditProductCategorieName,
        UUID: EditProductCategorieUUID,
        Description: EditProductCategorieDiscription,
      });
      // console.log(call);
      HandleGetProductCategories();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      notifyError(`${err.response.data}\n Status Code ${err.status}`);
    } finally {
      //
      setLoading(false);
      //
    }
  };
  //

  const HandleGetProductCategories = async () => {
    try {
      var req: any = await getAllProductCategories();
      setProductCategorieData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    HandleGetProductCategories();
  }, []);
  //

  //mui
  //methods
  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const editedRow: any = productcategorieData.find((row) => row.id === id);
    setEditProductCategorieID(editedRow.id);
    setEditProductCategorieName(editedRow.name);
    setEditProductCategorieUUID(editedRow.uuid);
    setEditProductCategorieDiscription(editedRow.description);
    handleOpenEditWarehouse();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  const handleDeleteClick = async (id: GridRowId) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleProductCategory({ id: id });
      console.log(call);
      HandleGetProductCategories();
      notifySuccess(call.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data) {
        notifyError(err.response.data);
      }
      if (err.response.data.name) {
        notifyError(err.response.data.name);
      }
    }
  };

  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "uuid", headerName: "UUID", width: 130 },
    { field: "description", headerName: "Discription", width: 130 },
    {
      field: "createdBy",
      valueGetter: (value, row) => {
        return `${row.CreatedByUser ? row.CreatedByUser.fullName : ""}`;
      },
      headerName: "Created By",
      width: 130,
    },
    { field: "createdAt", headerName: "Created At", width: 150 },
    {
      field: "updatedBy",
      valueGetter: (value, row) => {
        return `${row.UpdatedByUser ? row.UpdatedByUser.fullName : ""}`;
      },
      headerName: "Updated By",
      width: 130,
    },
    { field: "updatedAt", headerName: "Updated At", width: 150 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            //onClick={handleDeleteClick(id)}
            onClick={() => {
              AntdModal.confirm({
                title: "Confirm",
                content: "Are you sure you want to delete this record?",
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    {/* <Button>Custom Button</Button> */}
                    <CancelBtn />
                    <OkBtn />
                  </>
                ),
                onOk: () => {
                  handleDeleteClick(id);
                },
              });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="warehouse">
      <div className="ActionDiv">
        <button
          className="button"
          onClick={handleOpenNewWarehouse}
          onMouseEnter={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.backgroundColor = "#e0a800";
          }}
          onMouseLeave={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.backgroundColor = "#FFC107";
          }}
          onMouseDown={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.transform = "scale(0.95)";
          }}
          onMouseUp={(e) => {
            const target = e.target as HTMLButtonElement;
            target.style.transform = "scale(1)";
          }}
          style={{
            backgroundColor: "#FFC107",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            transition: "all 0.2s ease",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "4px",
          }}
        >
          New Categories
        </button>
      </div>
      <div className="warehouseDisplay"></div>

      {/* New warehouse mode */}
      <Modal
        open={openNewWarehouse}
        onClose={handleCloseNewWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", marginBottom: 2 }}
          >
            New Product Category
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleAddProductCategorie}
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {/* Category Name */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      className="productlabels"
                      style={{ marginBottom: "8px", fontSize: "14px" }}
                    >
                      Name:
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Category Name"
                      onChange={(event) =>
                        setNewProductCategorieName(event.target.value)
                      }
                      required
                      style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        fontSize: "14px",
                        outline: "none",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>

                  {/* Category UUID */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      className="productlabels"
                      style={{ marginBottom: "8px", fontSize: "14px" }}
                    >
                      UUID:
                    </label>
                    <input
                      type="text"
                      placeholder="Enter UUID (e.g., 01)"
                      onChange={(event) =>
                        setNewProductCategorieUUID(event.target.value)
                      }
                      maxLength={2}
                      required
                      style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        fontSize: "14px",
                        outline: "none",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>

                  {/* Category Description */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      className="productlabels"
                      style={{ marginBottom: "8px", fontSize: "14px" }}
                    >
                      Description:
                    </label>
                    <textarea
                      placeholder="Enter Product Category Description"
                      onChange={(event) =>
                        setNewProductCategorieDiscription(event.target.value)
                      }
                      style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        fontSize: "14px",
                        outline: "none",
                        width: "100%",
                        height: "100px",
                        boxSizing: "border-box",
                        resize: "none",
                      }}
                    />
                  </div>

                  {/* Add Button */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      className="addbtn"
                      type="submit"
                      style={{
                        backgroundColor: "#007BFF",
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "16px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                      onMouseOver={(e) =>
                        ((e.target as HTMLButtonElement).style.backgroundColor =
                          "#0056b3")
                      }
                      onMouseOut={(e) =>
                        ((e.target as HTMLButtonElement).style.backgroundColor =
                          "#007BFF")
                      }
                    >
                      Add Category
                    </button>
                  </div>
                </div>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>
      {/*  */}
      {/* Edit Product Category mode */}
      <Modal
        open={openEditWarehouse}
        onClose={handleCloseEditWareHouse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              Edit Product Category
            </h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Spin spinning={loading}>
              <form
                onSubmit={HandleUpdateProductCategory}
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  {/* Product Category Name */}
                  <label
                    className="productlabels"
                    style={{ fontWeight: "bold" }}
                  >
                    Name:
                    <input
                      type="text"
                      placeholder="Product Category Name"
                      value={EditProductCategorieName}
                      onChange={(event) =>
                        setEditProductCategorieName(event.target.value)
                      }
                      required
                      style={{
                        padding: "8px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>

                  {/* Product Category UUID */}
                  <label
                    className="productlabels"
                    style={{ fontWeight: "bold" }}
                  >
                    UUID:
                    <input
                      type="text"
                      placeholder="01"
                      value={EditProductCategorieUUID}
                      onChange={(event) =>
                        setEditProductCategorieUUID(event.target.value)
                      }
                      maxLength={2}
                      required
                      style={{
                        padding: "8px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>

                  {/* Product Category Description */}
                  <label
                    className="productlabels"
                    style={{ fontWeight: "bold" }}
                  >
                    Description:
                    <textarea
                      placeholder="Product Category Description"
                      value={EditProductCategorieDiscription}
                      onChange={(event) =>
                        setEditProductCategorieDiscription(event.target.value)
                      }
                      style={{
                        padding: "8px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        minHeight: "100px",
                      }}
                    />
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    className="addbtn"
                    type="submit"
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontSize: "16px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      ((e.target as HTMLButtonElement).style.backgroundColor =
                        "#0056b3")
                    }
                    onMouseOut={(e) =>
                      ((e.target as HTMLButtonElement).style.backgroundColor =
                        "#007bff")
                    }
                  >
                    Update Category
                  </button>
                </div>
              </form>
            </Spin>
          </Typography>
        </Box>
      </Modal>

      {/*  */}
      {/* Material ui warehose table */}
      {productcategorieData && productcategorieData.length > 0 ? (
        <DataGrid
          rows={productcategorieData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default ProductCategorie;
