import React from "react";
import "./Demo.css";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { useModuleContext } from "../../Contexts/ModuleContext";

//pages
import DashboardView from "./DashboardView/DashboardView";
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
//

///ui
//#antdesign
//icons
import {
  DashboardOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";
//
import { Button } from "antd";

//

const Demo1 = () => {
  //
  const navigate = useNavigate();
  //
  const { contextModuleId } = useModuleContext();
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={(event) => {
              navigate(".");
            }}
          >
            Demo1
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          <div className="moduleMngActionBtns">
            <nav>
              <NavLink to="." end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <DashboardOutlined />
                    Dashboard
                  </Button>
                )}
              </NavLink>

              <NavLink to="list" end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <UnorderedListOutlined />
                    SubModule1
                  </Button>
                )}
              </NavLink>

              <NavLink to="list" end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <UnorderedListOutlined />
                    SubModule2
                  </Button>
                )}
              </NavLink>

              <NavLink to="list" end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <UnorderedListOutlined />
                    SubModule3
                  </Button>
                )}
              </NavLink>

              <NavLink to="list" end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <SettingOutlined />
                    Configurations(V1)
                  </Button>
                )}
              </NavLink>
            </nav>
          </div>
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<DashboardView />} />
          <Route path="/list" element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
        </Routes>
      </div>
    </div>
  );
};

export default Demo1;
