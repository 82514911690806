import React, { useState } from "react";
import "./ProfileEdit.css";
//
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
//notification
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//
import { sessionStorage } from "es-storage";
//
import {
  ChangePassword,
  ChangeAvater,
  UpdateTelegramBotChatID,
} from "../../../API/userAPI";
//
import { QRCodeSVG } from "qrcode.react";
import { useTenantSubscriptionContext } from "../../../Contexts/TenantSubscriptionContext";
//
import { CheckTenantTelegramModuleAccess } from "../../../middleware/tenantModuleAccess";

const ProfileEdit = () => {
  const { modules, addModule } = useTenantSubscriptionContext();
  //
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassord, setConfirmPassord] = useState("");
  const [UserImage, setUserImage] = useState(sessionStorage.get("user_image"));
  const [telegramID, setTelegramID] = useState("");

  const handelChanhePassword = async (event: any) => {
    event.preventDefault();
    try {
      const req = await ChangePassword({
        id: "1",
        Old_Password: OldPassword,
        New_Password: NewPassword,
        Confirm_Passord: ConfirmPassord,
      });
      console.log(req);
      notifySuccess("Success");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  };
  //

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setUserImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const UpdateAvaterHandler = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      const call = await ChangeAvater({
        Image: UserImage,
      });
      console.log(call);
      // GetProducts();
      notifySuccess("Success");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  };

  const handleTelegramIDUpdate = async (event: any) => {
    event.preventDefault();
    try {
      const response = await UpdateTelegramBotChatID(telegramID);
      console.log(response);
      notifySuccess("Success");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    }
  };

  // Add your Telegram bot username/link here
  const telegramBotLink = "https://t.me/HabSyncTgBot";

  return (
    <div className="Profile_Edit">
      <h3>Profile Settings</h3>
      <div className="ChangeAvatar">
        <form onSubmit={UpdateAvaterHandler}>
          <label>
            Profile Avatar
            <div>
              <input
                type="file"
                accept="image/*"
                multiple={false}
                onChange={handleImageChange}
              />
              {UserImage && (
                <img
                  src={UserImage}
                  alt="Uploaded"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
          </label>
          <button type="submit">Update Avatar</button>
        </form>
      </div>
      <div className="ChangePassword">
        <h2>Change Password</h2>
        <form onSubmit={handelChanhePassword}>
          <Input.Password
            placeholder="Old Password"
            onChange={(event) => setOldPassword(event.target.value)}
            required
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            placeholder="New Password"
            onChange={(event) => setNewPassword(event.target.value)}
            required
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            placeholder="Confirm Password"
            onChange={(event) => setConfirmPassord(event.target.value)}
            required
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <button type="submit">Change Password</button>
        </form>
      </div>
      {CheckTenantTelegramModuleAccess(modules) ? (
        <div className="ChangeTelegramID">
          <h2>Telegram Bot Settings</h2>
          <div className="telegram-container">
            <div className="qr-section">
              <h3>Scan QR Code to Connect</h3>
              <QRCodeSVG
                value={telegramBotLink}
                size={200}
                level={"H"}
                includeMargin={true}
              />
              <p>
                Or click{" "}
                <a
                  href={telegramBotLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>{" "}
                to open Telegram bot
              </p>
            </div>
            <form onSubmit={handleTelegramIDUpdate}>
              <Input
                placeholder="Enter your Telegram Chat ID"
                value={telegramID}
                onChange={(event) => setTelegramID(event.target.value)}
                required
              />
              <button type="submit">Update Telegram ID</button>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileEdit;
